import React, { useState, useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import st from "geojson-bounds";

import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Api.helper";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
import markerred from "../../../assets/icons/marker-icon-2x-red.png";
import markergreen from "../../../assets/icons/marker-icon-2x-green.png";
import markeryellow from "../../../assets/icons/marker-icon-2x-yellow.png";
import markergold from "../../../assets/icons/marker-icon-2x-gold.png";
import markerorange from "../../../assets/icons/marker-icon-2x-orange.png";
import markerblue from "../../../assets/icons/marker-icon-2x-blue.png";
import markershadow from "../../../assets/icons/marker-shadow.png";

import nepalDistrictGEO from "../../../assets/map/nepal-acesmndr.json";
import nepalGEO from "../../../assets/map/nepalone.json";
import { DISTRICTMAPPING, PROVINCEMAPPING } from "../../../constant";
import { SelectField } from "../../common/selectField/selectfield.common";
import { LEVEL } from "../DashboardPage";

const NepalMapComponent = ({
  level = "national",
  province = "state1",
  mapGEOSON,
  positionBound,
  markerList,
}) => {
  // const mapRef = React.useRef();
  const geojsonRef = React.useRef();
  const [displayMarker, setDisplayMarker] = useState(markerList);

  useEffect(() => {
    markerList?.length > 0 && setDisplayMarker(markerList);
  }, [markerList]);
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;

  // const [pos, setPos] = useState({
  //   lat: 28.438035,
  //   lng: 84.754714,
  //   zoom: 7,
  // });
  // const position = [pos.lat, pos.lng];
  var greenIcon = [
    L.icon({
      // color:1
      iconUrl: markergreen,
      shadowUrl: markershadow,

      // iconSize:     [20, 70], // size of the icon
      shadowSize: [41, 41], // size of the shadow
      iconSize: [25, 41],
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [12, 41], // the same for the shadow
      popupAnchor: [1, -41], // point from which the popup should open relative to the iconAnchor
    }),
    L.icon({
      // color:2
      iconUrl: markeryellow,
      shadowUrl: markershadow,

      // size of the icon
      shadowSize: [41, 41], // size of the shadow
      iconSize: [25, 41],
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [12, 41], // the same for the shadow
      popupAnchor: [1, -41], // point from which the popup should open relative to the iconAnchor
    }),
    L.icon({
      // color:3
      iconUrl: markergold,
      shadowUrl: markershadow,

      // size of the icon
      shadowSize: [41, 41], // size of the shadow
      iconSize: [25, 41],
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [12, 41], // the same for the shadow
      popupAnchor: [1, -41], // point from which the popup should open relative to the iconAnchor
    }),

    L.icon({
      // color:4
      iconUrl: markerorange,
      shadowUrl: markershadow,

      // size of the icon
      shadowSize: [41, 41], // size of the shadow
      iconSize: [25, 41],
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [12, 41], // the same for the shadow
      popupAnchor: [1, -41], // point from which the popup should open relative to the iconAnchor
    }),
    L.icon({
      // color:5
      iconUrl: markerred,
      shadowUrl: markershadow,

      // size of the icon
      shadowSize: [41, 41], // size of the shadow
      iconSize: [25, 41],
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [12, 41], // the same for the shadow
      popupAnchor: [1, -41], // point from which the popup should open relative to the iconAnchor
    }),
    L.icon({
      // color:6
      iconUrl: markerblue,
      shadowUrl: markershadow,

      // iconSize:     [20, 70], // size of the icon
      shadowSize: [41, 41], // size of the shadow
      iconSize: [25, 41],
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [12, 41], // the same for the shadow
      popupAnchor: [1, -41], // point from which the popup should open relative to the iconAnchor
    }),
  ];

  // useEffect(() => {
  //   let mount = true;
  //   const fetch = async () => {
  //     let site_map_result = await api(APIS.site_map_result);
  //     if (mount) {
  //       setSiteMapData(site_map_result?.data);
  //     }
  //   };
  //   fetch();
  //   return () => {
  //     mount = false;
  //   };
  // }, []);

  const filterMarker = (color) => {
    const filterdata = markerList.filter((item) => item.color === color);
    setDisplayMarker(filterdata);
  };
  const resetMarker = () => {
    setDisplayMarker(markerList);
  };
  return (
    <>
      <div className="nepalmap-container">
        <div className="nepalmap">
          <MapContainer
            // center={position}
            // zoom={pos.zoom}
            zoomControl={true}
            bounds={positionBound}
            // ref={mapRef}
            // scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <MarkerClusterGroup showCoverageOnHover={false}> */}
            {displayMarker?.length > 0 &&
              displayMarker?.map((item, index) => {
                return (
                  item.latitude &&
                  item.longitude && (
                    <Marker
                      icon={greenIcon[item.color - 1]}
                      key={index}
                      position={[item.latitude, item.longitude]}
                      eventHandlers={{
                        click: () => {
                          // console.log("marker clicked");
                          navigate(routes["GX Sites"].path + "/" + item.id);
                        },
                        mouseover: (e) => {
                          e.target.openPopup();
                        },
                        mouseout: (e) => {
                          e.target.closePopup();
                        },
                      }}
                    >
                      <Popup>
                        <div>
                          <div>{item.name}</div>
                          <div>
                            Working Modules: {item.working_modules_count}/
                            {item.total_modules_count}
                          </div>
                        </div>
                      </Popup>
                    </Marker>
                  )
                );
              })}
            {/* </MarkerClusterGroup> */}

            <GeoJSON
              ref={geojsonRef}
              data={mapGEOSON}
              // coordsToLatLng={coordsToLatLng}
              style={{
                color: "#007bb0",
                weight: 3,
                fillOpacity: 0.4,
                fillColor: "#6BD0FC",
              }}
            />
          </MapContainer>
          <div className="nepalmap-legend">
            <div className="clear">
              <p onClick={resetMarker}>Clear</p>
            </div>
            <div
              className="nepalmap-legend-item"
              onClick={() => filterMarker(5)}
            >
              <div className="nepalmap-legend-item-index1" /> Delay More than 7
              days
            </div>
            <div
              className="nepalmap-legend-item"
              onClick={() => filterMarker(4)}
            >
              <div className="nepalmap-legend-item-index2" />
              Delay for 5-6 days
            </div>
            <div
              className="nepalmap-legend-item"
              onClick={() => filterMarker(3)}
            >
              <div className="nepalmap-legend-item-index3" />
              Delay for 3-4 days
            </div>
            <div
              className="nepalmap-legend-item"
              onClick={() => filterMarker(2)}
            >
              <div className="nepalmap-legend-item-index4" />
              Delay for 1-2 days
            </div>
            <div
              className="nepalmap-legend-item"
              onClick={() => filterMarker(1)}
            >
              <div className="nepalmap-legend-item-index5" />
              Operational
            </div>
            <div
              className="nepalmap-legend-item"
              onClick={() => filterMarker(6)}
            >
              <div className="nepalmap-legend-item-index6" />
              Non Functional
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NepalMap = ({ provinceList }) => {
  const [level, setLevel] = useState("national");
  const [province, setProvince] = useState("");
  const [provinceID, setProvinceID] = useState("");
  const [district, setDistrict] = useState("");
  const [districtID, setDistrictID] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mapGEOSON, setMapGEOSON] = useState(nepalGEO);
  const [positionBound, setPositionBound] = useState([
    [26.348379, 80.050926],
    [30.47146, 88.204673],
  ]);

  const [siteMapData, setSiteMapData] = useState([]);
  const [markerData, setMarkerData] = useState([]);

  useEffect(() => {
    let mount = true;
    const fetch = async () => {
      let site_map_result = await api(APIS.site_map_result);
      if (mount) {
        setSiteMapData(site_map_result?.data);
      }
    };
    fetch();
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    let mount = true;
    const fetch = async () => {
      let districtResponse = await api(APIS.get_district + province + "/");
      if (mount && districtResponse?.status === 200) {
        setDistrictList(districtResponse?.data);
      }
    };
    if (province && level === "district") fetch();
    return () => {
      mount = false;
    };
  }, [province, level]);

  useEffect(() => {
    if (
      level === "national" ||
      (level === "province" && !!province) ||
      (level === "district" && !!district)
    ) {
      onChange();
    }
  }, [level, province, district]);

  useEffect(() => {
    if (
      level === "national" ||
      (level === "province" && !!province) ||
      (level === "district" && !!district)
    ) {
      updateMarker();
    }
  }, [level, province, district, siteMapData?.length]);

  const updateMarker = () => {
    const siteMapDataClone = [...siteMapData];
    switch (true) {
      case level === "national":
        setMarkerData(siteMapDataClone);
        break;

      case level === "province" && !!province: {
        const updateData = siteMapDataClone.filter(
          ({ province_id }) => province_id === +province
        );
        setMarkerData(updateData);
        break;
      }

      case level === "district" && !!district: {
        const updateData = siteMapDataClone.filter(
          ({ district_id }) => district_id === +district
        );
        setMarkerData(updateData);
        break;
      }

      default:
        setMarkerData(siteMapDataClone);
        break;
    }
  };

  const onChangeLevel = (data) => {
    setLevel(data);
  };

  const onChangeDistrict = (data) => {
    const fff = districtList.find(({ id }) => +data === id);
    setDistrict(data);
    setDistrictID(fff.district_id);
  };

  const onChangeProvince = (data) => {
    setDistrict("");
    setDistrictID("");
    const fff = provinceList.find(({ id }) => +data === id);
    setProvince(data);
    setProvinceID(fff.province_id);
  };
  const getGeoJson = () => {
    switch (true) {
      case level === "national":
        return nepalGEO;

      case level === "province" && !!provinceID:
        return getProvinceGeo(provinceID);

      case level === "district" && !!districtID:
        return getDistrictGeo(districtID);

      default:
        return nepalGEO;
    }
  };

  const getPosition = () => {
    const ddd = st.extent(getGeoJson());

    return [
      [ddd[1], ddd[0]],
      [ddd[3], ddd[2]],
    ];
  };

  const onChange = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    const geo = getGeoJson();
    setMapGEOSON(geo);
    const positionbound = getPosition();
    setPositionBound(positionbound);
  };

  const getDistrictGeo = (districtId) => {
    const districtsGeo = [...nepalDistrictGEO.features];
    const districtFound = districtsGeo.find(
      ({ id }) => DISTRICTMAPPING[id] === districtId
    );
    return !!districtFound ? districtFound : nepalDistrictGEO.features[0];
  };

  const getProvinceGeo = (province) => {
    const provinceGeo = [...nepalGEO.features];
    const provinceFound = provinceGeo.find(
      ({ properties }) => PROVINCEMAPPING[properties.id] === province
    );
    return !!provinceFound ? provinceFound : nepalGEO.features[0];
  };

  return (
    <div className="card">
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          columnGap: 20,
        }}
      >
        <div className="form">
          <label>Filter by Level</label>
          <SelectField
            options={LEVEL}
            getOptionLabel="name"
            getOptionValue="value"
            onChangeValue={(item) => {
              onChangeLevel(item?.value);
            }}
            value={LEVEL?.find((item) => item.value === level)}
            placeholder="Search By Level"
            isClearable={true}
            isSearchable={true}
          />
          {/* <div style={{ display: "flex", flexDirection: "row" }}>
            <select
              value={level}
              onChange={(e) => {
                onChangeLevel(e.target.value);
              }}
              style={{ width: 200 }}
            >
              <option value="">Select Level</option>
              <option value={"national"}>National</option>
              <option value={"province"}>Province</option>
              <option value={"district"}>District</option>
            </select>
          </div> */}
        </div>

        {level === "province" || level === "district" ? (
          <div className="form">
            <label>Province</label>
            <SelectField
              options={provinceList}
              getOptionLabel="name"
              getOptionValue="id"
              onChangeValue={(item) => {
                onChangeProvince(item?.id);
              }}
              value={provinceList?.find(
                (item) => Number(item.id) === Number(province)
              )}
              placeholder="Search By Province"
              isClearable={true}
              isSearchable={true}
            />
            {/* <div style={{ display: "flex", flexDirection: "row" }}>
              <select
                value={province}
                onChange={(e) => onChangeProvince(e.target.value)}
                style={{ width: 200 }}
              >
                <option value="">Select Level</option>
                {provinceList?.length > 0 &&
                  provinceList?.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div> */}
          </div>
        ) : null}

        {level === "district" ? (
          <div className="form">
            <label>District</label>
            <SelectField
              options={districtList}
              getOptionLabel="name"
              getOptionValue="id"
              onChangeValue={(item) => {
                onChangeDistrict(item?.id);
              }}
              value={districtList?.find(
                (item) => Number(item.id) === Number(district)
              )}
              placeholder="Search By District"
              isClearable={true}
              isSearchable={true}
            />
            {/* <div style={{ display: "flex", flexDirection: "row" }}>
              <select
                value={district}
                onChange={(e) => {
                  onChangeDistrict(e.target.value);
                }}
                style={{ width: 200 }}
              >
                <option value="">Select Level</option>
                {districtList?.length > 0 &&
                  districtList?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div> */}
          </div>
        ) : null}
      </div>
      {loading ? null : (
        <NepalMapComponent
          level={level}
          {...{ mapGEOSON, positionBound }}
          markerList={markerData}
        />
      )}
    </div>
  );
};
export default NepalMap;
