import React, { useState, useEffect, useCallback } from "react";
import MaterialTable from "material-table";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import DatePicker from "react-datepicker";
import { api } from "../../../helpers/Api.helper";
import { APIS } from "../../../config/Api.config";
import Pagination from "@material-ui/lab/Pagination";
import { useNavigation } from "react-auth-navigation";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useComponentDidUpdate, useDebounceValue } from "../../../hooks";
import { SelectField } from "../../common/selectField/selectfield.common";
import { FormLabel } from "../../common/formLabel/formLabel.common";
import moment from "moment";

const ATTRIBUTE = [
  { name: "MTB+RIF I", value: "MTB+RIF I" },
  { name: "MTB+RIF R", value: "MTB+RIF R" },
  { name: "MTB+RIF S", value: "MTB+RIF S" },
  { name: "MTB NOT DETECTED", value: "MTB ND" },
  { name: "NO_RESULT", value: "NO_RESULT" },
  { name: "ERROR", value: "ERROR" },
];
const MTBResult = () => {
  const { location, history } = useNavigation();

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const [pageNumber, setPageNumber] = useState(() =>
    query.get("page") ? Number(query.get("page")) : 1
  );

  const [fromDate, setFromDate] = useState(() =>
    query.get("fromDate") ? new Date(query.get("fromDate")) : null
  );
  const [toDate, setToDate] = useState(() =>
    query.get("toDate") ? new Date(query.get("toDate")) : null
  );
  const [siteValue, setSiteValue] = useState(query.get("site") || "");
  const [machineId, setMachineId] = useState(query.get("machinecode") || "");
  const [instrument, setInstrument] = useState(query.get("instrument") || "");
  const [sampleId, setSampleId] = useState(query.get("sample") || "");
  const [mtbResult, setMtbResult] = useState(query.get("mtbResultType") || "");
  const instrumentValue = useDebounceValue(instrument);
  const sampleIdValue = useDebounceValue(sampleId);

  const [tableData, setTableData] = useState();
  const [tableDataArray, setTableDataArray] = useState([]);
  const [siteList, setSiteList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingReport, setIsLoadingReport] = useState(true);
  const [dateRange, setDateRange] = useState(() => {
    if (!!query.get("fromDate") && !!query.get("toDate")) {
      console.log(query.get("fromDate"));
      let dateRangeFrom = query.get("fromDate");
      let dateRangeTo = query.get("toDate");
      return `&fromDate=${dateRangeFrom}&toDate=${dateRangeTo}`;
    } else return "";
  });

  const fetchReport = useCallback(async (body) => {
    setIsLoadingReport(true);
    const response = await api(APIS.get_report_data, "POST", body);
    if (response.status === 200) {
      setTableData(response?.data);
      setIsLoadingReport(false);
    }
    // setIsLoadingReport(false);
  }, []);

  const fetchResult = async (newPage = 1) => {
    history.replace(`${location.pathname}?page=${newPage}`);
    setIsLoading(true);

    let machine_result = await api(APIS.machine_result + `?page=${newPage}`);

    if (machine_result?.status === 200) {
      window.scrollTo(0, 0);
      setTableDataArray(machine_result?.data);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  //FETCH SITE LIST
  useEffect(() => {
    let mount = true;
    if (mount) {
      const fetchSiteList = async () => {
        let site_list_result = await api(APIS.site_list).catch((error) => {
          console.log(site_list_result.error);
        });
        setSiteList(site_list_result?.data);
      };
      fetchSiteList();
    }

    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    const body = {};
    if (fromDate && toDate) {
      body.start_date = fromDate ? moment(fromDate).format("YYYY-MM-DD") : null;
      body.end_date = toDate ? moment(toDate).format("YYYY-MM-DD") : null;
    }
    if (!!siteValue) {
      body.site_id = siteValue;
    }
    if (!!machineId) {
      body.machine_code = machineId;
    }
    if (!!instrumentValue) {
      body.instrument_serial_number = instrumentValue;
    }
    if (!!sampleIdValue) {
      body.sample_id = sampleIdValue;
    }
    if (!!mtbResult) {
      body.mtb_result = mtbResult;
    }
    fetchReport(body);
    // }
  }, [
    fetchReport,
    dateRange,
    siteValue,
    machineId,
    instrumentValue,
    sampleIdValue,
    mtbResult,
  ]);

  useEffect(() => {
    let mount = true;
    if (mount) {
      (fromDate && toDate) ||
      siteValue ||
      machineId ||
      sampleIdValue ||
      instrumentValue ||
      mtbResult
        ? searchResult(
            siteValue,
            machineId,
            sampleIdValue,
            instrumentValue,
            mtbResult,
            false,
            pageNumber
          )
        : fetchResult(pageNumber);
    }
    return () => {
      mount = false;
    };
  }, [pageNumber]);

  useComponentDidUpdate(() => {
    let mount = true;
    if (mount) {
      if (pageNumber === 1) {
        (fromDate && toDate) ||
        siteValue ||
        machineId ||
        sampleIdValue ||
        instrumentValue ||
        mtbResult
          ? searchResult(
              siteValue,
              machineId,
              sampleIdValue,
              instrumentValue,
              mtbResult,
              false,
              1
            )
          : fetchResult(1);
      } else {
        setPageNumber(1);
      }
    }

    return () => {
      mount = false;
    };
    // fromDate && toDate && searchReport(fromDate, toDate, siteValue);
  }, [
    dateRange,
    siteValue,
    machineId,
    instrumentValue,
    sampleIdValue,
    mtbResult,
  ]);

  useEffect(() => {
    if (!!fromDate && !!toDate) {
      let dateRangeFrom = fromDate
        ? moment(fromDate).format("YYYY-MM-DD")
        : null;
      let dateRangeTo = toDate ? moment(toDate).format("YYYY-MM-DD") : null;
      setDateRange(`&fromDate=${dateRangeFrom}&toDate=${dateRangeTo}`);
    }
  }, [fromDate, toDate]);

  const page = async (event, newPage = 1) => {
    setPageNumber(newPage);
  };

  const searchReport = async (
    bodyFromDate,
    bodyToDate,
    siteId,
    bodyMachineId,
    bodySampleId,
    bodyInstrument,
    bodyMtbResult
  ) => {
    setIsLoadingReport(true);

    let dateRangeFrom = null;
    let dateRangeTo = null;
    // siteId = siteId != null ? Number(siteId) : siteId;
    if (bodyFromDate && bodyToDate) {
      dateRangeFrom = bodyFromDate;
      dateRangeTo = bodyToDate;
    }

    const response = await api(APIS.get_report_data, "POST", {
      end_date: dateRangeTo,
      start_date: dateRangeFrom,
      site_id: siteId,
      machine_code: bodyMachineId,
      sample_id: bodySampleId,
      instrument_serial_number: bodyInstrument,
      mtb_result: bodyMtbResult,
    });
    if (response?.status === 200) {
      setTableData(response?.data);
      setIsLoadingReport(false);
    }
    setIsLoadingReport(false);
  };

  const searchResult = async (
    bodySiteId,
    bodyMachineId,
    bodySampleId,
    bodyInstrument,
    bodyMtbResult,
    loader,
    newPage = 1
  ) => {
    setIsLoading(true);
    let dateRangeFrom = fromDate ? moment(fromDate).format("YYYY-MM-DD") : null;
    let dateRangeTo = toDate ? moment(toDate).format("YYYY-MM-DD") : null;
    let filter = "";

    if (dateRangeFrom && dateRangeTo) {
      filter += `&fromDate=${dateRangeFrom}&toDate=${dateRangeTo}`;
    }

    if (!!bodySiteId) {
      filter += `&site=${bodySiteId}`;
    }
    if (!!bodyMachineId) {
      filter += `&machinecode=${bodyMachineId}`;
    }
    if (!!bodySampleId) {
      filter += `&sample=${bodySampleId}`;
    }
    if (!!bodyInstrument) {
      filter += `&instrument=${bodyInstrument}`;
    }
    if (!!bodyMtbResult) {
      filter += `&mtbResultType=${bodyMtbResult.replace("+", "%2B")}`;
    }
    // history;
    history.replace(`${location.pathname}?page=1${filter}`);
    // navigate(`${location.pathname}?page=1${filter}`);

    let machine_result = await api(
      APIS.machine_result + `search/?page=${newPage}`,
      "POST",
      {
        end_date: dateRangeTo,
        start_date: dateRangeFrom,
        site_id: bodySiteId,
        machine_code: bodyMachineId,
        sample_id: bodySampleId,
        instrument_serial_number: bodyInstrument,
        mtb_result: bodyMtbResult,
      }
    );
    if (machine_result?.status === 200) {
      window.scrollTo(0, 0);
      setTableDataArray(machine_result?.data);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  function formatDate(date) {
    if (!date) return null;
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const onDownload = async () => {
    const response = await api("api/results/download_csv/", "POST", {
      start_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
      end_date: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
      site_id: siteValue,
      machine_code: machineId,
      sample_id: sampleIdValue,
      instrument_serial_number: instrumentValue,
      mtb_result: mtbResult,
    });
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "mtb_result.csv";
    link.href = URL.createObjectURL(
      new Blob([response.data], { type: "text/csv" })
    );
    link.click();
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="resultPage-container">
      <div className="resultPage">
        <form>
          <div className="form-container">
            <div className="formWrapper spacing ">
              <FormLabel label="Date From" className="form">
                <DatePicker
                  showIcon
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="yyyy-MM-dd"
                  openToDate={new Date()}
                  placeholderText="Click to select a date"
                />
              </FormLabel>
              <FormLabel label="Date To" className="form">
                <DatePicker
                  showIcon
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="yyyy-MM-dd"
                  openToDate={new Date()}
                  placeholderText="Click to select a date"
                />
              </FormLabel>
            </div>

            {/* <div className="formWrapper">
                <label>Search by Date Range</label>
                <div className="spacing">
                  <KeyboardDatePicker
                    disableToolbar
                    label="Date From"
                    format="yyyy-MM-dd"
                    margin="normal"
                    value={fromDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    onChange={(date, value) => {
                      console.log("date,value", { date, value });
                      setFromDate(value);
                    }}
                  />

                  <KeyboardDatePicker
                    disableToolbar
                    label="Date To"
                    format="yyyy-MM-dd"
                    margin="normal"
                    value={toDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    onChange={(date, value) => {
                      console.log("date,value", { date, value });
                      setToDate(value);
                    }}
                  />
                </div>
              </div> */}

            <div className="formWrapper spacing">
              <FormLabel label="Search by Site" className="form">
                <SelectField
                  options={siteList}
                  getOptionLabel="name"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    console.log("siteData", item);
                    setSiteValue(item?.id);
                  }}
                  value={siteList?.find(
                    (item) => Number(item.id) === Number(siteValue)
                  )}
                  placeholder="Search By Site"
                  isClearable={true}
                  isSearchable={true}
                />
              </FormLabel>
              <FormLabel label="GXMachine" className="form">
                <SelectField
                  options={
                    siteList?.find(
                      (result) => result.id === parseInt(siteValue)
                    )?.machines
                  }
                  getOptionLabel="code"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    console.log("siteData", item);
                    setMachineId(item?.id);
                  }}
                  value={siteList
                    ?.find((result) => result.id === parseInt(siteValue))
                    ?.machines?.find(
                      (item) => Number(item.id) === Number(machineId)
                    )}
                  placeholder="Search By Machine"
                  isClearable={true}
                  isSearchable={true}
                />
              </FormLabel>
            </div>

            <div className="formWrapper spacing">
              <FormLabel label="Instrument Serial number" className="form">
                <input
                  style={{ height: 40 }}
                  value={instrument}
                  onChange={(e) => setInstrument(e.target.value)}
                />
              </FormLabel>
              <FormLabel label="Sample id" className="form">
                <input
                  style={{ height: 40 }}
                  value={sampleId}
                  onChange={(e) => setSampleId(e.target.value)}
                />
              </FormLabel>
            </div>

            <div className="formWrapper spacing">
              <FormLabel label="Search" className="form">
                <SelectField
                  options={ATTRIBUTE}
                  getOptionLabel="name"
                  getOptionValue="value"
                  onChangeValue={(item) => {
                    console.log("siteData", item);
                    setMtbResult(item?.value);
                  }}
                  value={ATTRIBUTE?.find((item) => item.value === mtbResult)}
                  placeholder="Search By Attribute"
                  isClearable={true}
                  isSearchable={true}
                />
              </FormLabel>

              {/* <div className="editmachinestatus-form-site form">
                  <label>Search</label>
                  <SelectField
                    options={ATTRIBUTE}
                    getOptionLabel="name"
                    getOptionValue="value"
                    onChangeValue={(item) => {
                      console.log("siteData", item);
                      setMtbResult(item?.value);
                    }}
                    value={ATTRIBUTE?.find((item) => item.value === mtbResult)}
                    placeholder="Search By Attribute"
                    isClearable={true}
                    isSearchable={true}
                  />
                </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // marginTop: 20,
                  height: 80,
                }}
              >
                <Button
                  type="button"
                  onClick={onDownload}
                  size="small"
                  style={{ marginLeft: 10 }}
                  disabled={
                    isLoading || typeof tableDataArray.results === "undefined"
                  }
                  variant="contained"
                  color="primary"
                  className="button"
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        </form>
        <>
          {!tableData && isLoadingReport && (
            <div className={classes.root}>
              <CircularProgress />
            </div>
          )}
          <TableContainer component={Paper} style={{ marginBottom: 20 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>MTB ND</TableCell>
                  <TableCell align="right">MTB+RIF S</TableCell>
                  <TableCell align="right">MTB+RIF R</TableCell>
                  <TableCell align="right">MTB+RIF I</TableCell>
                  <TableCell align="right">Error</TableCell>
                  <TableCell align="right">Invalid</TableCell>
                  <TableCell align="right">No Results</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingReport ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {tableData?.MTB_NOT_DETECTED}
                    </TableCell>
                    <TableCell align="right">{tableData?.MTB_RIF_S}</TableCell>
                    <TableCell align="right">{tableData?.MTB_RIF_R}</TableCell>
                    <TableCell align="right">{tableData?.MTB_RIF_I}</TableCell>
                    <TableCell align="right">{tableData?.ERROR}</TableCell>
                    <TableCell align="right">{tableData?.INVALID}</TableCell>
                    <TableCell align="right">{tableData?.NO_RESULT}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {!tableDataArray && isLoading && (
            <div className={classes.root}>
              <CircularProgress />
            </div>
          )}
          <MaterialTable
            columns={[
              { title: "Site", field: "machine.site_name" },
              { title: "Machine ID", field: "machine.code" },
              {
                title: "Instrument Serial Number",
                field: "instrument_serial_number",
              },
              { title: "Module Serial Number", field: "module.serial_no" },
              { title: "Reagent Lot Id", field: "reagent_lot_id" },
              { title: "MTB Result", field: "mtb_result" },
              { title: "RIF Resistance Result", field: "rif_result" },
              { title: "Sample ID", field: "sample_id" },
              {
                title: "Date",
                field: "date",
                render: (rowData) => <div>{formatDate(rowData.date)}</div>,
              },
              {
                title: "Error",
                field: "error",
                render: (rowData) => (
                  <div className="tooltip">
                    {rowData.error.slice(0, 10)}{" "}
                    {/* <span className="tooltiptext">{rowData.error}</span> */}
                  </div>
                ),
              },

              {
                title: "Error Desc.",
                field: "error",
                render: (rowData) => (
                  <div className="tooltip" style={{ width: 150 }}>
                    {rowData.error.slice(11)}{" "}
                    {/* <span className="tooltiptext">{rowData.error}</span> */}
                  </div>
                ),
              },

              { title: "Version", field: "software_version" },
            ]}
            data={tableDataArray?.results}
            isLoading={isLoading}
            options={{
              toolbar: false,
              filtering: true,
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              search: false,
              pageSizeOptions: [5, 10],
              pageSize: 10,
              paging: false,
            }}
          />
        </>

        <div style={{ width: "100%", display: "flex" }}>
          <Pagination
            style={{
              marginLeft: "auto",
              marginTop: 20,
              display: "inline-block",
            }}
            count={Math.ceil(tableDataArray?.count / 100)}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={page}
          />
        </div>
      </div>
    </div>
  );
};

export default MTBResult;
