import React, { useState, useEffect } from "react";
import ContainerBox from "../common/hoc/ContainerBox.hoc";

import MTBResult from "./result/MTBResult";
import Header from "../common/header/Header.common";

const ResultPage = () => {
  const [mount, setMount] = useState(true);
  useEffect(() => {
    return () => {
      setMount(false);
    };
  }, []);
  return (
    <ContainerBox>
      <Header title="MTB/RIF Results" />
      <div className="result">{mount && <MTBResult />}</div>
    </ContainerBox>
  );
};
export default ResultPage;
