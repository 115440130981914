import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { APIS } from "../../config/Api.config";
import { api } from "../../helpers/Api.helper";
import { toast } from "react-toastify";

import ContainerBox from "../common/hoc/ContainerBox.hoc";
import Header from "../common/header/Header.common";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
const ChangePasswordPage = () => {
  const { register, setValue, handleSubmit, watch } = useForm({});
  const [error, setError] = useState(false);
  const passwordWatch = watch("password");

  const [type, setType] = useState("password");
  const [conFirmType, setConFirmType] = useState("password");
  const [oldType, setOldType] = useState("password");
  const onSubmit = async (data) => {
    let body = {
      old_password: data.oldPassword,
      new_password: data.password,
    };
    const changePassword = await api(APIS.change_password, "POST", body);
    if (changePassword?.status === 200) {
      setValue("oldPassword", "");
      setValue("password", "");
      setValue("passwordConfirm", "");
      toast.success("changed successful", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    } else {
      toast.error(changePassword.data.error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  };
  const testValue = (event) => {
    if (passwordWatch !== event.target.value) {
      setError(true);
    } else {
      setError(false);
    }
  };
  return (
    <ContainerBox>
      <Header title="Change Password" />
      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="addgxsite-form">
            <div className="addgxsite-form-row">
              <div className="form">
                <label>Old Password</label>
                <div className="passowrd-container">
                  <input
                    name="oldPassword"
                    type={oldType}
                    ref={register({ required: true })}
                  />
                  <div className="passowrd-eye">
                    {oldType === "password" ? (
                      <IoMdEye onClick={() => setOldType("text")} size={24} />
                    ) : (
                      <IoMdEyeOff
                        onClick={() => setOldType("password")}
                        size={24}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="addgxsite-form-row">
              <div className="form">
                <label>New Password</label>
                <div className="passowrd-container">
                  <input
                    name="password"
                    type={type}
                    ref={register({ required: true })}
                  />
                  <div className="passowrd-eye">
                    {type === "password" ? (
                      <IoMdEye onClick={() => setType("text")} size={24} />
                    ) : (
                      <IoMdEyeOff
                        onClick={() => setType("password")}
                        size={24}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="addgxsite-form-row">
              <div className="form">
                <label>Confirm Password</label>
                <div className="passowrd-container">
                  <input
                    name="passwordConfirm"
                    type={conFirmType}
                    ref={register({
                      validate: (value) =>
                        value === passwordWatch || "The passwords do not match",
                    })}
                    onChange={(e) => testValue(e)}
                  />
                  <div className="passowrd-eye">
                    {conFirmType === "password" ? (
                      <IoMdEye
                        onClick={() => setConFirmType("text")}
                        size={24}
                      />
                    ) : (
                      <IoMdEyeOff
                        onClick={() => setConFirmType("password")}
                        size={24}
                      />
                    )}
                  </div>
                </div>
                {error && (
                  <p style={{ color: "red" }}>The passwords do not match</p>
                )}
              </div>
            </div>
            <div className="addgxsite-form-row">
              <button type="submit" onClick={handleSubmit(onSubmit)}>
                submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </ContainerBox>
  );
};

export default ChangePasswordPage;
