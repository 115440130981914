import React, { useEffect } from "react";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import CovidResult from "./result/CovidResult";
import Header from "../common/header/Header.common";

const CovidResultPage = () => {
  useEffect(() => {}, []);
  return (
    <ContainerBox>
      <Header title="Covid Results" />
      <div className="result">
        <CovidResult />
      </div>
    </ContainerBox>
  );
};

export default CovidResultPage;
