import React, { useState, useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import ContainerBox from "../../common/hoc/ContainerBox.hoc";
import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Api.helper";
import Header from "../../common/header/Header.common";
import EditComponent from "./EditComponent";

const EditGxSite = () => {
  const { params } = useNavigation();
  const [siteDetailData, setSiteDetailData] = useState();

  useEffect(() => {
    if (params.id) {
      const fetch = async () => {
        let siteDetail = await api(APIS.sites_response + params.id + "/");
        setSiteDetailData(siteDetail?.data);
      };
      fetch();
    }
  }, []);

  return (
    <ContainerBox>
      <Header title="Edit Gx Site" />
      {siteDetailData ? (
        <EditComponent editData={siteDetailData} />
      ) : (
        <div>Loading...</div>
      )}
    </ContainerBox>
  );
};

export default EditGxSite;
