import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "react-auth-navigation";
import moment from "moment";
import { toast } from "react-toastify";
import { Button } from "@material-ui/core";
import { api } from "../../../helpers/Helpers";
import { APIS } from "../../../config/Api.config";
import { ActivityIndicator } from "../../common/hoc";
import { useNotification } from "../../../context";
import { SelectField } from "../../common/selectField/selectfield.common";

const STATUS = [
  {
    value: "picked",
    name: "Picked",
  },
  {
    value: "working",
    name: "Working",
  },
  {
    value: "solved",
    name: "Solved",
  },
];

const EditComponent = (props) => {
  const { onNotificationHandler } = useNotification();
  const { userId, userRole } = useAuth();
  // const { navigate, routes } = navigation
  const { editData, reloadData, goBack } = props;

  console.log("editData", editData);
  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      site: editData?.site,
      machine: editData?.machine,
    },
  });
  const siteWatch = watch("site");
  const {
    register: registercomment,
    handleSubmit: handleSubmitComment,
    // errors: errorsComment,
    setValue: setValueComment,
  } = useForm();
  const [siteList, setSiteList] = useState([]);
  const [siteListLoading, setSiteListLoading] = useState(true);
  const [ticketLoader, setTicketLoader] = useState(false);
  const [commentLoader, setCommentLoader] = useState(false);
  const [getUserList, setGetUserList] = useState([]);

  const [siteData, setSiteData] = useState(editData?.site);
  const [machineId, setMachineId] = useState(editData?.machine);
  const [assignTo, setAssignTo] = useState(editData.assigned_to);
  const [status, setStatus] = useState(editData.status);

  useEffect(() => {
    let mount = true;
    const fetch = async () => {
      let site_list_result = await api(APIS.site_list);
      if (mount) {
        setSiteList(site_list_result?.data);
        setSiteListLoading(false);
      }
      setSiteListLoading(false);
    };
    fetch();
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    let mount = true;
    const onGetUserHandler = async (id) => {
      let userList = [];
      let res = await api(APIS.getSiteUsers, "POST", { site: id });

      if (res?.data[3]?.length > 0) {
        for (let i = 0; i < res?.data[3]?.length; i++) {
          userList.push(res?.data[3][i]);
        }
      }
      if (res?.data[6]?.length > 0) {
        for (let i = 0; i < res?.data[1]?.length; i++) {
          userList.push(res?.data[6][i]);
        }
      }
      if (mount) {
        setGetUserList(userList.filter(Boolean));
      }
    };

    if (siteWatch) {
      onGetUserHandler(siteWatch);
    }
    return () => {
      mount = false;
    };
  }, [siteWatch]);

  const onSubmit = async (data) => {
    console.log("data", data);

    const body = {
      site: siteData,
      machine: machineId,
    };

    if (editData.description !== data?.description) {
      body["description"] = data.description;
    }

    // if (editData.status !== data?.status) {
    //   body["status"] = data?.status;
    // }

    if (!editData) body["created_by"] = userId;
    if (Number(assignTo) !== Number(editData?.assigned_to)) {
      body["assigned_to"] = assignTo;
    }
    console.log("body", body);
    // return;

    // {
    //   description: data.description,
    //   site: data.site,
    //   machine: data.machine,
    //   status: editData.status,
    //   created_by: userId,
    //   assigned_to: data.user,
    // }

    setTicketLoader(true);
    let edit_ticket_result = await api(
      APIS.ticket + `${editData.id}/`,
      "PATCH",
      body
    );

    if (edit_ticket_result.status === 200) {
      if (editData.status !== status) {
        let edit_ticket_status = await api(APIS.ticket_status_change, "POST", {
          ticket: editData.id,
          status: status,
        });

        if (edit_ticket_status.status === 200) {
          onNotificationHandler();
          setTicketLoader(false);
          toast.success("Ticket edited!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          goBack();
        }
      } else {
        onNotificationHandler();
        setTicketLoader(false);
        toast.success("Ticket edited!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        goBack();
      }
    }
    setTicketLoader(false);
  };

  const onSubmitComment = async (data) => {
    setCommentLoader(true);

    let body = {
      comment_text: data.comment,
      // commented_by: userId,
      ticket: editData.id,
    };
    let commentResponse = await api(APIS.ticket_comment, "POST", body);
    if (commentResponse.status === 201) {
      onNotificationHandler();
      setCommentLoader(false);
      setValueComment("comment", "");
      toast.success("Comment Added!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      reloadData();
    }
    setCommentLoader(false);
  };
  return (
    <div className="addticket">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="addticket-form">
          <div className="editmachinestatus-form-site form">
            <label>GX-SITE</label>
            <SelectField
              options={siteList}
              getOptionLabel="name"
              getOptionValue="id"
              onChangeValue={(item) => {
                console.log("siteData", item);
                setSiteData(item?.id);
              }}
              value={siteList?.find((item) => item.id === siteData)}
              placeholder="Select GxSite"
              isClearable={true}
              isSearchable={true}
              isLoading={siteListLoading}
              isDisabled={userRole === "3"}
            />
          </div>

          <div className="editmachinestatus-form-site form">
            <label>GXMachine</label>
            <SelectField
              options={
                siteList?.find((result) => result.id === parseInt(siteData))
                  ?.machines
              }
              getOptionLabel="code"
              getOptionValue="id"
              onChangeValue={(item) => {
                console.log("siteData", item);
                setMachineId(item?.id);
              }}
              value={siteList
                ?.find((result) => result.id === parseInt(siteData))
                ?.machines?.find((item) => item.id === machineId)}
              placeholder="Select MAcine"
              isClearable={true}
              isSearchable={true}
              isLoading={siteListLoading}
              isDisabled={userRole === "3"}
            />
          </div>

          <div className="addgxsite-form-row">
            <div className="addgxsite-form-site form">
              <label>Status</label>
              <SelectField
                options={STATUS}
                getOptionLabel="name"
                getOptionValue="value"
                onChangeValue={(item) => {
                  setStatus(item?.value);
                }}
                value={STATUS?.find((item) => item.value === status)}
                placeholder="Select Status"
                isClearable={true}
                isSearchable={true}
                isDisabled={userRole === "2"}
              />
            </div>
          </div>

          {userRole !== "6" && (
            <div className="editmachinestatus-form-site form">
              <label> Assign To</label>
              <SelectField
                options={getUserList}
                getOptionLabel="username"
                getOptionValue="id"
                onChangeValue={(item) => {
                  setAssignTo(item?.id);
                }}
                value={getUserList?.find((item) => item.id === assignTo)}
                placeholder="Select GxSite"
                isClearable={true}
                isSearchable={true}
              />
            </div>
          )}

          <div className="addgxsite-form-name form">
            <label>Description</label>
            <textarea
              rows="5"
              name="description"
              ref={register({ required: true })}
              defaultValue={editData ? editData.description : null}
              className={`${errors.name && "showerror"} `}
            />
          </div>

          <div className="addgxsite-form-row">
            <ActivityIndicator animating={ticketLoader}>
              <Button type="submit" variant="contained" color="primary">
                submit
              </Button>
            </ActivityIndicator>
          </div>
        </div>
      </form>

      <div className="addticket-comment">
        Comments
        {editData?.ticket_events?.filter(({ event_type }) => event_type === 1)
          .length > 0 && (
          <ul className="addticket-comment-show">
            {editData?.ticket_events
              .filter(({ event_type }) => event_type === 1)
              ?.map(({ ticket_event_comment, actor }, index) => {
                return (
                  <li
                    key={index}
                    style={{ listStyle: "none" }}
                    className="addticket-comment-show-list"
                  >
                    {Number(actor) === Number(userId) ? (
                      <div className="addticket-comment-show-list-comment">
                        <div></div>
                        <div className="right">
                          <div
                            className="addticket-comment-show-list-comment-msg"
                            style={{ background: "#e5e5e5" }}
                          >
                            {ticket_event_comment[0]?.comment_text}
                          </div>
                          <span className="addticket-comment-show-list-comment-date">
                            {moment(ticket_event_comment[0]?.created_at).format(
                              "YYYY-MM-DD"
                            )}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="addticket-comment-show-list-comment">
                        <div className="left">
                          <div
                            className="addticket-comment-show-list-comment-msg"
                            style={{ background: "#52C8FB", color: "white" }}
                          >
                            {ticket_event_comment[0]?.comment_text}
                          </div>
                          <span className="addticket-comment-show-list-comment-date">
                            {moment(ticket_event_comment[0]?.created_at).format(
                              "YYYY-MM-DD"
                            )}
                          </span>
                        </div>
                        <div />
                      </div>
                    )}
                  </li>
                );
              })}
          </ul>
        )}
        <form onSubmit={handleSubmitComment(onSubmitComment)}>
          Add Comment
          <div className="form">
            <textarea
              name="comment"
              ref={registercomment}
              rows="6"
              cols="50"
              placeholder="Comment"
            />
          </div>
          <div className="addgxsite-form-row">
            <ActivityIndicator animating={commentLoader}>
              <Button type="submit" variant="contained" lor="primary">
                {" "}
                Comment
              </Button>
            </ActivityIndicator>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditComponent;
