import React from "react";

import { ActivityIndicator } from "../../common/hoc";

import { IoCloseCircleSharp } from "react-icons/io5";
import { LineGraph } from ".";
import { CustomDatePicker } from "../../common/datepicker/Datepicker.common";

export const GraphComponent = ({
  onClose,
  loading,
  data,
  graphType,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  return (
    <div className="graphcontainer">
      <div className="graphcontainer-graph">
        <div style={{ display: "flex" }}>
          <CustomDatePicker
            onChange={(data) => {
              setFromDate(data);
            }}
            value={fromDate}
            placeholder="Start Date"
          />
          <div style={{ marginLeft: 10 }}>
            <CustomDatePicker
              onChange={(data) => {
                setToDate(data);
              }}
              value={toDate}
              placeholder="To Date"
            />
          </div>
        </div>
        {!!onClose && (
          <div style={{ cursor: "pointer" }} onClick={onClose}>
            <IoCloseCircleSharp size={30} color="red" />
          </div>
        )}
      </div>
      <div style={{ marginTop: 20 }}>
        Active status of {graphType}{" "}
        {!!fromDate && !!toDate && (
          <strong>
            from {fromDate} To {toDate}
          </strong>
        )}
      </div>
      <ActivityIndicator animating={loading && !data?.length}>
        <LineGraph data={data} {...{ graphType }} />
      </ActivityIndicator>
    </div>
  );
};
