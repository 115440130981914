export const DISTRICTMAPPING = {
  1: "CNFRaFUfLjo",
  2: "VoOb1wMwKp0",
  3: "ZULmwVN4Zfv",
  4: "BdLcDbLQd88",
  5: "O5p5idrXdiw",
  6: "i6gMyoP9ReK",
  7: "hasbXH0dTqM",
  8: "eW2b8SUYRWt",
  9: "S8J6p6tGXNb",
  10: "uHEl9oRZm8L",
  11: "PUPCyKcUwlS",
  12: "bX87SmYR3TJ",
  13: "grMJpaDdSrD",
  14: "LnJ8MTOmgGa",
  15: "B7X957nA1lM",
  16: "Wep3D4POB3H",
  17: "YmFSl06J6cs",
  18: "DLyaDAF00hm",
  19: "EXKX4gJ9D6Y",
  20: "mMGyx6JG7wj",
  21: "gannbFbizVo",
  22: "IOHIfpVUJGp",
  23: "HfIRB3HRHvk",
  24: "QfZ2pVPRFmz",
  25: "elOK8Y2b3Qn",
  26: "ZVmULoI6vcV",
  27: "JH8RPnwv8kB",
  28: "QzI3Btz60Ne",
  29: "tSE6ctwvZmP",
  30: "U8Rk7sYzFmK",
  31: "j4HVtKmVtwQ",
  32: "Fh294RrB7an",
  33: "A3R7UT64jHf",
  34: "j0gE31YxwHQ",
  35: "rsZaqJl5R0A",
  36: "YnUoe8z0OJY",
  37: "iqLM1XBMxyu",
  38: "ZIo35uAAfZo",
  39: "Iqy9A4jXY9l",
  40: "kfrKchZfqGr",
  41: "Yacp1fLlWut",
  42: "yP2GELa6inf",
  43: "l8tZrJ55yzg",
  44: "ebbAyOhorzo",
  45: "F5xI4hl77mT",
  46: "cMWLZfK0O4z",
  47: "WfE2smSUNDt",
  48: "bWmwn3sMsG0",
  49: "A3TeVhjjS2u",
  50: "q7VB2VrUr83",
  51: "ICSVN3EZGcy",
  52: "qoayxucpZqH",
  53: "bjS2Lp3tLxv",
  54: "N7ioObCpMP3",
  55: "xGQwwqLQ819",
  56: "zkFZKX5RX1A",
  57: "BITtrV4c0xf",
  58: "HL5fSUsp2vJ",
  59: "RZxElQxEbZN",
  60: "ewPc6zXy53n",
  61: "D51gR5DC5vt",
  62: "SJQ2e9HUvaG",
  63: "jJmNryYp6Mc",
  64: "PdRpVxkVd9a",
  65: "wYKHZ0iyjfd",
  66: "kBRVmS9IKRE",
  67: "ycBkX3TlIrL",
  68: "P53hVYydxdc",
  69: "RSAZqrdfXy5",
  70: "GnpIwWbdxdl",
  71: "HkwA8YQgJK6",
  72: "JBTkOU5m0Bu",
  73: "vjL1uSC10Jh",
  74: "t9XsLKdMwA1",
  75: "uyGkoQ4F9rT",
  76: "NSLL7YIXBJH",
  77: "BywOwm8jUhf",
};

export const PROVINCEMAPPING = {
  1: "RVc3XoVoNRf",
  2: "a6W190BanBu",
  3: "hi16ZuHEWaY",
  4: "GvgqqErqwFP",
  5: "Zx3boDXh1Q5",
  6: "fvN7GZvNAOB",
  7: "wtU6v09Kbe0",
};
