import React, { useContext, useEffect, useState } from "react";
import { api } from "../helpers/Api.helper";
import { APIS } from "../config/Api.config";
export const NotificationContext = React.createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationContextProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);

  const onNotificationHandler = () => {
    console.log("this is notification");
    fetch();
  };

  const onDeleteNotificationHandler = async (callback) => {
    let notification_response = await api(
      `${APIS.notification}delete_all_notification/`
    );
    console.log("notification_response", notification_response);
    if (notification_response?.status === 200) {
      callback();
      setNotificationCount(0);
    }
  };

  const fetch = async (event) => {
    let notification_response = await api(`${APIS.notification}seen_count/`);
    console.log("notification_response", notification_response);
    if (notification_response?.status === 200) {
      setNotificationCount(notification_response?.data.count);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notificationCount,
        onNotificationHandler,
        onDeleteNotificationHandler,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
