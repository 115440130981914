import React, { useState } from "react";
import { toast } from "react-toastify";

import { IoMdEye, IoMdEyeOff } from "react-icons/all";
import UsernameLogo from "../../assets/icons/Username.png";
import PasswordLogo from "../../assets/icons/Password.png";

import { useAuth, useNavigation } from "react-auth-navigation";
import { useForm } from "react-hook-form";
import { APIS } from "../../config/Api.config";
import { api } from "../../helpers/Api.helper";
import { InvertedButton } from "../common/button/Button.common";
import { ActivityIndicator } from "../common/hoc";
const LoginPage = () => {
  const { navigation } = useNavigation();
  const { routes, navigate } = navigation;
  const { handleSubmit, register } = useForm();
  const { handleLogin } = useAuth();
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("password");
  const onSubmit = async (data) => {
    setLoader(true);
    let body = {
      username: data.username,
      password: data.password,
    };
    let res = await api(APIS.login, "POST", body, {
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    if (res?.status === 200) {
      const { token, id, user_type } = res?.data;
      setLoader(false);
      toast.success("Login successful", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });

      handleLogin(token, id, user_type);
    } else if (res?.status === 400) {
      setLoader(false);

      toast.error(res?.data?.error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
    setLoader(false);
  };

  return (
    <div className="login-container">
      <div className="login">
        <div className="login-title">GXMIS USER LOGIN</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-input">
            <div className="login-input-logo">
              <img src={UsernameLogo} alt="USERNAME" />
            </div>

            <input
              name="username"
              ref={register({ required: true })}
              type="text"
              className="login-input-field"
              placeholder="Username"
            />
          </div>

          <div className="login-input">
            <div className="login-input-logo">
              <img src={PasswordLogo} alt="PASSWORD" />
            </div>
            <input
              name="password"
              type={type}
              ref={register({ required: true })}
              className="login-input-field"
              placeholder="Password"
            />
            <div className="login-input-eye">
              {type === "password" ? (
                <IoMdEye onClick={() => setType("text")} size={24} />
              ) : (
                <IoMdEyeOff onClick={() => setType("password")} size={24} />
              )}
              {/* <img
                src={PasswordLogo}
                alt="PASSWORD"
                // style={{ width: 30, objectFit: "contain" }}
              /> */}
            </div>
          </div>

          <div className="login-submit">
            <ActivityIndicator animating={loader}>
              <InvertedButton
                type="submit"
                title="LOGIN"
                style={{ width: "100%", paddingTop: 10, paddingBottom: 10 }}
                onClick={() => false}
              />
            </ActivityIndicator>
          </div>
        </form>
        <div
          className="login-click"
          onClick={() => navigate(routes["Reset Password"].path)}
        >
          Forget Password?
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

// engineer_ritesh
// root@1234
