import React, { useState } from "react";
import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Api.helper";
import { IoStatsChartSharp } from "react-icons/io5";
import { GraphComponent, ModuleCard } from ".";
import { useComponentDidUpdate } from "../../../hooks";

export const MachineCard = (props) => {
  const { item } = props;
  const [machineGraphVisible, setMachineGraphVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const fetchMachineGraphStats = async () => {
    setLoading(true);
    setMachineGraphVisible(true);
    let site_result = await api(`${APIS.machinenotworkingdata}/${item.id}/`);

    if (site_result?.status === 200) {
      setStats(site_result?.data);
      setLoading(false);
    }
    setLoading(false);
    // setGxDetail(site_result?.data);
  };

  useComponentDidUpdate(() => {
    if (!fromDate || !toDate) return;
    (async () => {
      setLoading(true);
      setMachineGraphVisible(true);

      const body = { from_date: fromDate, to_date: toDate };

      let site_result = await api(
        `${APIS.machinenotworkingdata}/${item.id}/`,
        "POST",
        body
      );

      console.log("site_result", site_result);
      if (site_result?.status === 200) {
        setStats(site_result?.data);
        setLoading(false);
      }
      setLoading(false);
      // setGxDetail(site_result?.data);
    })();
  }, [fromDate, toDate]);

  return (
    <div className="machines">
      {machineGraphVisible && (
        <GraphComponent
          onClose={() => setMachineGraphVisible(false)}
          loading={loading}
          data={stats}
          graphType="Machine"
          {...{ fromDate, setFromDate, toDate, setToDate }}
        />
      )}
      <div className="machines-list">
        <div className="gxdetail-machines-code" style={{ marginTop: 20 }}>
          <div className="gxdetail-machines-code-name">
            <span>
              Machine Code : <strong>{item.code}</strong>
            </span>
            <span
              onClick={fetchMachineGraphStats}
              style={{ padding: 10, cursor: "pointer" }}
            >
              <IoStatsChartSharp />
            </span>
          </div>
        </div>
        <div className="serials">
          {item.serials
            .filter((serial) => serial.is_active === true)
            .map((itemSerial, indexSerial) => {
              return (
                <div key={indexSerial}>
                  <div className="gxdetail-machines-item">
                    <div>
                      <div className="gxdetail-machines-item-serial">
                        Serial Number : {itemSerial.serial_no}
                      </div>
                    </div>
                    <ModuleCard {...{ itemSerial }} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
