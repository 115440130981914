import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiCalendarAlt } from "react-icons/bi";
// export default function MaterialUIPickers(props) {
//   // The first commit of Material-UI
//   const [fromDate, setFromDate] = React.useState(
//     new Date(props.initialDate || Date.now())
//   );

//   const handleDateChange = (date) => {
//     setFromDate(date);
//     props.DateChange(date);
//   };

//   return (
//     <MuiPickersUtilsProvider utils={DateFnsUtils}>
//       <KeyboardDatePicker
//         margin="normal"
//         id="date-picker-dialog"
//         label={props.label}
//         format="MM/dd/yyyy"
//         value={fromDate}
//         onChange={handleDateChange}
//         KeyboardButtonProps={{
//           "aria-label": "change date",
//         }}
//       />
//     </MuiPickersUtilsProvider>
//   );
// }

export const CustomDatePicker = React.forwardRef((props, ref) => {
  const { value, placeholder = "Date", onChange } = props;

  const [customDate, setCustomDate] = React.useState(() =>
    value?.length
      ? moment(value, "YYYY-MM-DD", true).format("ddd, DD MMM YYYY HH:mm:ss ZZ")
      : ""
  );
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div
      onClick={onClick}
      ref={ref}
      style={{
        border: "1px solid #a1a1a1",
        width: 200,
        padding: "8px 4px",
        borderRadius: 4,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {value?.length ? moment(value).format("YYYY-MM-DD") : placeholder}
      </div>
      <BiCalendarAlt
        style={{
          fontSize: 20,
          color: "#a1a1a1",
          // position: "absolute",
          // right: 0,
        }}
      />
    </div>
  ));
  const onChangeDate = (date) => {
    console.log("date", date);
    setCustomDate(date);
    onChange(moment(date).format("YYYY-MM-DD"));
  };
  return (
    <DatePicker
      selected={customDate}
      //   selected={moment(value, "YYYY-MM-DD").format("MMMM Do YYYY, h:mm:ss a")}
      onChange={onChangeDate}
      customInput={<CustomInput />}
    />
  );
});
