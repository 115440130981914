import axios from "axios";
import { BASE_URL } from "../config/Config";

export async function api(url, method, body, headers) {
  const token = localStorage.getItem("token");

  const config = {
    method: "GET",
    baseURL: BASE_URL,
    url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };

  if (headers) {
    config.headers = headers;
  }

  if (method) {
    config.method = method;
  }

  if (body) {
    config.data = body;
  }

  let response;
  try {
    response = await axios(config);
    // console.log("response",response)
    return response;
  } catch (e) {
    return e?.response?.data?.message || e?.response || e?.message;
  }
}
