import React from "react";

const SigninPage = () => {
  return (
    <div>
      SignIn Page
    </div>
  )
}

export default SigninPage;