import React, { useState } from "react";
import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Api.helper";
import moment from "moment";

import { IoCloseCircle, IoStatsChartSharp } from "react-icons/io5";
import { GraphComponent } from ".";
import { useComponentDidUpdate } from "../../../hooks";
import { AiFillEye } from "react-icons/ai";
import { CustomModal } from "../../common/customModal/CustomModal";
import { ModuleHistory } from "./ModuleHistory";

const getBackgroundActiveColor = (updated_at) => {
  const diffDays = moment().diff(moment(updated_at), "days");
  if (diffDays > 7) {
    return "red";
  }
  return "green";
};

export const ModuleCard = ({ itemSerial }) => {
  const [moduleGraphVisible, setModuleGraphVisible] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [moduleStats, setModuleStats] = useState([]);
  const [activeId, setActiveId] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const fetchModulesGraphStats = async (id) => {
    setModuleLoading(true);
    setModuleGraphVisible(true);
    let site_result = await api(`${APIS.modulenotworkingdata}/${id}/`);
    if (site_result?.status === 200) {
      setModuleStats(site_result?.data);
      setModuleLoading(false);
    }
    setModuleLoading(false);
    // setGxDetail(site_result?.data);
  };

  useComponentDidUpdate(() => {
    if (!fromDate || !toDate) return;
    (async () => {
      setModuleLoading(true);
      setModuleGraphVisible(true);

      // const body = { from_date: fromDate, to_date: toDate };

      let site_result = await api(`${APIS.modulenotworkingdata}/${activeId}/`);
      if (site_result?.status === 200) {
        setModuleStats(site_result?.data);
        setModuleLoading(false);
      }
      setModuleLoading(false);
    })();
  }, [activeId, fromDate, toDate]);

  return (
    <div className="gxdetail-machines-item-module">
      {/* <div>
          <div className="gxdetail-machines-item-module-code">
            Modules of machine: {itemSerial.serial_no}
          </div>
        </div> */}
      <div className="gxdetail-machines-item-module-each">
        {itemSerial.modules
          .filter((module) => module.is_active === true)
          .map((itemModule, indexModule) => {
            return (
              <div
                key={itemModule?.id}
                style={{
                  background: getBackgroundActiveColor(itemModule.updated_at),
                  color: itemModule.is_active ? "white" : "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                {itemModule.serial_no}{" "}
                <span
                  onClick={() => {
                    setActiveId(itemModule?.id);
                    fetchModulesGraphStats(itemModule?.id);
                  }}
                  style={{ padding: 10, cursor: "pointer" }}
                >
                  <IoStatsChartSharp />
                </span>
                <CustomModal
                  displayElement={
                    <span style={{ cursor: "pointer" }}>
                      <AiFillEye />
                    </span>
                  }
                  style={{ width: "60vw", height: "80vh" }}
                >
                  {({ onCloseModalHandler }) => (
                    <div style={{}}>
                      <div
                        onClick={onCloseModalHandler}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                        }}
                      >
                        <IoCloseCircle color="red" size={20} />
                      </div>
                      <ModuleHistory
                        serialNo={itemModule.serial_no}
                        // moduleId={536}
                      />
                    </div>
                  )}
                </CustomModal>
              </div>
            );
          })}
      </div>

      {moduleGraphVisible && (
        <div style={{ marginTop: 10 }}>
          <GraphComponent
            onClose={() => setModuleGraphVisible(false)}
            loading={moduleLoading}
            data={moduleStats}
            graphType="Module"
            {...{ fromDate, setFromDate, toDate, setToDate }}
          />
        </div>
      )}
    </div>
  );
};
