import React from "react";

const NotFoundPage = () => {
  return (
    <div className="notfound-container">
        <div className="notfound">
            404 PAGE NOT FOUND!!!
        </div>
            
    </div>
  )
}

export default NotFoundPage;