import React from "react";
import { Dropdown } from "react-uicomp";
import { useNavigation, useAuth } from "react-auth-navigation";

import { DropdownMenu, DropdownMenuItem } from "../dropdown/Dropdown.common";
import { MdMenu } from "react-icons/all";

const Header = ({ title }) => {
  const { isLoggedIn, userRole, userId, handleLogout, setSideNavVisible } =
    useAuth();
  const { navigation } = useNavigation();
  const { navigate } = navigation;

  const detailHandler = () => {
    navigate("/users/" + userId);
  };

  return isLoggedIn ? (
    <div className="header-container">
      <div className="header">
        <div className="header-top">
          <div className="header-top-left">
            {/* <div onClick={() => togglenav(showSidebar)}  >
                            <span className="icon">
                                <AiOutlineMenu/>

                            </span>
                        </div> */}
            <div
              className="header-menu"
              onClick={() => setSideNavVisible((prev) => !prev)}
            >
              <MdMenu />
            </div>
            <span className="header-title">{title}</span>
          </div>
          <div className="header-top-right">
            <Dropdown
              triggerElement={() => (
                <div className="header-top-right-logged">
                  <span className="header-top-right-logged-role">
                    {userRole === "1" && "Admin"}
                    {userRole === "2" && "Center"}
                    {userRole === "3" && "Engineer"}
                    {userRole === "4" && "Province"}
                    {userRole === "5" && "District"}
                    {userRole === "6" && "site"}
                  </span>
                </div>
              )}
            >
              <DropdownMenu>
                <DropdownMenuItem onClick={detailHandler}>
                  User Profile
                </DropdownMenuItem>
                <DropdownMenuItem danger={true} onClick={handleLogout}>
                  LOGOUT
                </DropdownMenuItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Header;
