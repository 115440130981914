import React, { useState, useEffect } from "react";
import ContainerBox from "../../common/hoc/ContainerBox.hoc";
import { api } from "../../../helpers/Helpers";
import { APIS } from "../../../config/Api.config";
import Header from "../../common/header/Header.common";
import { Button } from "@material-ui/core";
import { useNavigation } from "react-auth-navigation";

const UsersDetails = (props) => {
  const [userData, setUserData] = useState();
  const { match } = props;
  const { params } = match;
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;

  const fetchUser = async () => {
    const res = await api(APIS.users + params.id + "/");
    setUserData(res?.data);
  };
  useEffect(() => {
    fetchUser();
  }, []);
  {
    userData && console.log(userData);
  }

  const passwordChangeHandler = () => {
    navigate(routes["ChangePassword"].path);
  };
  const detailChangeHandler = () => {
    console.log("/users/edit/" + userData?.id);
    navigate("/users/edit/" + userData?.id);
  };

  return (
    <ContainerBox>
      <Header title="User Detail" />
      <div>
        <p>Users Detail Page</p>
        {userData && <p>Username:{userData.username}</p>}
        {userData && <p>First Name:{userData.first_name}</p>}
        {userData && <p>Last Name:{userData.last_name}</p>}
        {userData && <p>User Type:{userData.user_type}</p>}

        {userData?.user_type && (
          <Button
            variant="contained"
            color="primary"
            onClick={detailChangeHandler}
          >
            Edit User Detail
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={passwordChangeHandler}
        >
          Change Password
        </Button>
      </div>
    </ContainerBox>
  );
};
export default UsersDetails;
