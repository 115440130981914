import React, { useState, useEffect } from "react";
import { useNavigation, useAuth } from "react-auth-navigation";
import moment from "moment";
import { toast } from "react-toastify";
import ContainerBox from "../../common/hoc/ContainerBox.hoc";
import { Button } from "@material-ui/core";
import { api } from "../../../helpers/Helpers";
import { APIS } from "../../../config/Api.config";
import { ActivityIndicator } from "../../common/hoc";
import Header from "../../common/header/Header.common";

const ViewComponent = (props) => {
  const { userId } = useAuth();
  const { ticketDetail, reloadData } = props;
  const [comment, setComment] = useState("");

  const [commentLoader, setCommentLoader] = useState(false);

  const onSubmitComment = async (e) => {
    e.preventDefault();
    setCommentLoader(true);

    let body = {
      comment_text: comment,
      // commented_by: userId,
      ticket: ticketDetail.id,
    };
    let commentResponse = await api(APIS.ticket_comment, "POST", body);
    if (commentResponse.status === 201) {
      setCommentLoader(false);
      setComment("");
      toast.success("Comment Added!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      reloadData();
    }
    setCommentLoader(false);
  };
  return (
    <div className="addticket">
      <div className="addticket-comment">
        <KeyValue title="Site" value={ticketDetail?.site_name} />
        <KeyValue title="Machine" value={ticketDetail?.machine_code} />
        <KeyValue title="Status" value={ticketDetail?.status} />
        <KeyValue title="Description" value={ticketDetail?.description} />
        <div
          style={{
            fontSize: 20,
            background: "#52C8FB",
            display: "inline-block",
            padding: 20,
            margin: "20px 0px",
          }}
        >
          <strong style={{ color: "white" }}>Ticket Event Timeline</strong>{" "}
        </div>
        {ticketDetail?.ticket_events?.length > 0 && (
          <ul className="addticket-comment-show">
            {ticketDetail?.ticket_events.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{ listStyle: "none" }}
                  className="addticket-comment-show-list"
                >
                  <div className="addticket-comment-show-list-comment">
                    {item.event_type === 4 ? (
                      <strong>Ticket Created</strong>
                    ) : item.event_type === 3 ? (
                      <>
                        <strong>
                          Ticket Assigned to{" "}
                          {
                            item?.ticket_event_assign_change[0]
                              ?.now_assigned_to_name
                          }
                        </strong>
                        <span> by {item.actor_name}</span>
                      </>
                    ) : item.event_type === 2 ? (
                      <>
                        <strong>
                          Status Changed to{" "}
                          {item.tickets_status_change[0]?.now_status}
                        </strong>
                        <span> by {item.actor_name}</span>
                      </>
                    ) : item.event_type === 1 ? (
                      <div>
                        Comment added:
                        <strong>
                          <i>{item.ticket_event_comment[0]?.comment_text}</i>
                        </strong>
                        <span> by {item.actor_name}</span>
                      </div>
                    ) : null}
                    <span className="addticket-comment-show-list-comment-date">
                      {moment(item?.created_at).format("YYYY-MM-DD hh:mm A")}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
        Comments
        {ticketDetail?.ticket_events?.filter(
          ({ event_type }) => event_type === 1
        ).length > 0 && (
          <ul className="addticket-comment-show">
            {ticketDetail?.ticket_events
              .filter(({ event_type }) => event_type === 1)
              ?.map(({ ticket_event_comment, actor, actor_name }, index) => {
                return (
                  <li
                    key={index}
                    style={{ listStyle: "none" }}
                    className="addticket-comment-show-list"
                  >
                    {Number(actor) === Number(userId) ? (
                      <div className="addticket-comment-show-list-comment">
                        <div></div>
                        <div className="right">
                          <div
                            className="addticket-comment-show-list-comment-msg"
                            style={{ background: "#e5e5e5" }}
                          >
                            {ticket_event_comment[0]?.comment_text}
                          </div>
                          <div className="addticket-comment-show-list-comment-bottom">
                            <span className="addticket-comment-show-list-comment-bottom-date">
                              {" "}
                              {actor_name}
                            </span>
                            <span className="addticket-comment-show-list-comment-bottom-date">
                              {moment(
                                ticket_event_comment[0]?.created_at
                              ).format("YYYY-MM-DD")}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="addticket-comment-show-list-comment">
                        <div className="left">
                          <div
                            className="addticket-comment-show-list-comment-msg"
                            style={{ background: "#52C8FB", color: "white" }}
                          >
                            {ticket_event_comment[0]?.comment_text}
                          </div>
                          <div className="addticket-comment-show-list-comment-bottom">
                            <span className="addticket-comment-show-list-comment-bottom-date">
                              {moment(
                                ticket_event_comment[0]?.created_at
                              ).format("YYYY-MM-DD")}
                            </span>
                            <span className="addticket-comment-show-list-comment-bottom-date">
                              {" "}
                              {actor_name}
                            </span>
                          </div>
                        </div>
                        <div />
                      </div>
                    )}
                  </li>
                );
              })}
          </ul>
        )}
        <form onSubmit={onSubmitComment}>
          Add Comment
          <div className="form">
            <textarea
              name="comment"
              // ref={registercomment}
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              rows="6"
              cols="50"
              placeholder="Comment"
            />
          </div>
          <div className="addgxsite-form-row">
            <ActivityIndicator animating={commentLoader}>
              <Button
                type="submit"
                variant="contained"
                lor="primary"
                disabled={!comment}
              >
                {" "}
                Comment
              </Button>
            </ActivityIndicator>
          </div>
        </form>
      </div>
    </div>
  );
};

const KeyValue = ({ title, value }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: 10,
        marginBottom: 10,
      }}
    >
      <div>
        <strong style={{ fontSize: 18 }}>{title}: </strong>
      </div>
      <div style={{ fontSize: 18 }}>{value}</div>
    </div>
  );
};
const ViewTicket = (props) => {
  const { params } = useNavigation();
  const [ticketDetail, setTicketDetail] = useState();
  // const [fetchTicketDetail, setFetchTicketDetail] = useState(false);
  const [fetchTicketDetailLoader, setFetchTicketDetailLoader] = useState(false);

  const goBack = () => {
    props.history.goBack();
  };

  const fetchDetail = async () => {
    let ticket_detail = await api(APIS.ticket + `${params.id}`);

    if (ticket_detail.status === 200) {
      setTicketDetail(ticket_detail?.data);
    }
    setFetchTicketDetailLoader(false);
  };
  useEffect(() => {
    setFetchTicketDetailLoader(true);
    fetchDetail();
  }, []);

  return (
    <ContainerBox>
      <Header title="Edit Ticket" />
      {fetchTicketDetailLoader ? (
        <div style={{ width: "60vw", marginTop: 20 }}>
          <ActivityIndicator animating={true} />
        </div>
      ) : (
        <ViewComponent
          ticketDetail={ticketDetail}
          reloadData={fetchDetail}
          goBack={goBack}
        />
      )}
      {/* <ActivityIndicator animating={fetchTicketDetailLoader || !ticketDetail}>
        <ViewComponent
          ticketDetail={ticketDetail}
          reloadData={fetchDetail}
          goBack={goBack}
        />
      </ActivityIndicator> */}
    </ContainerBox>
  );
};

export default ViewTicket;
