import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import { Button, CircularProgress, Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useNavigation, useAuth } from "react-auth-navigation";
import { Modal } from "react-uicomp";
import { toast } from "react-toastify";
import MaterialTable from "material-table";

import DatePicker from "react-datepicker";
import { api } from "../../helpers/Api.helper";
import { APIS } from "../../config/Api.config";
import Pagination from "@material-ui/lab/Pagination";
import { GrClose } from "react-icons/all";
import Header from "../common/header/Header.common";
import { ActivityIndicator } from "../common/hoc";
import { useComponentDidUpdate, useInput } from "../../hooks";
import { useCallback } from "react";
import { FormLabel } from "../common/formLabel/formLabel.common";
import { SelectField } from "../common/selectField/selectfield.common";
import moment from "moment";
const TicketPage = () => {
  const { navigation, location, history } = useNavigation();
  const { routes, navigate } = navigation;
  const { userRole } = useAuth();
  const { register } = useForm();

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const [pageNumber, setPageNumber] = useState(query.get("page") || 1);
  const [status, setStatus] = useState(query.get("status") || "");

  const [tableDataArray, setTableDataArray] = useState();
  const [visible, setVisible] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [ticketData, setTicketData] = useState();
  const [ticketListLoading, setTicketListLoading] = useState(false);
  const [exportTicketListLoading, setExportTicketListLoading] = useState(false);
  const [provinceList, setProvinceList] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [siteList, setSiteList] = useState([]);
  const [siteValue, setSiteValue] = useState(() =>
    query.get("site") ? query.get("site") : ""
  );

  const [provinceValue, setProvinceValue] = useState("");

  const page = async (event, newPage = 1) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    const fetch_ticket_data = async () => {
      let ticket_data_response = await api(APIS.ticket_data);
      console.log(ticket_data_response?.data);
      setTicketData(ticket_data_response?.data);
    };
    fetch_ticket_data();
  }, []);

  useEffect(() => {
    const fetchProvince = async () => {
      let provinceList = await api(APIS.get_province);
      setProvinceList(provinceList?.data?.results);
    };
    fetchProvince();
  }, []);

  //FETCH SITE LIST
  useEffect(() => {
    let mount = true;
    if (mount) {
      const fetchSiteList = async () => {
        let site_list_result = await api(APIS.site_list).catch((error) => {});
        setSiteList(site_list_result?.data);
      };
      fetchSiteList();
    }

    return () => {
      mount = false;
    };
  }, []);

  useComponentDidUpdate(() => {
    if (pageNumber === 1) {
      !!provinceValue || !!status || !!siteValue || (!!fromDate && !!toDate)
        ? onSearch(status, siteValue, provinceValue, 1)
        : fetch(1);

      let filter = "";
      if (!!status) {
        filter += `&status=${status}`;
      }

      history.replace(`${location.pathname}?page=1${filter}`);
    } else {
      setPageNumber(1);
    }
  }, [status]);

  useComponentDidUpdate(() => {
    if (pageNumber === 1) {
      !!provinceValue || !!status || !!siteValue || (!!fromDate && !!toDate)
        ? onSearch(status, siteValue, provinceValue, 1)
        : fetch(1);

      let filter = "";
      if (!!status) {
        filter += `&status=${status}`;
      }
      if (!!siteValue) {
        filter += `&site=${siteValue}`;
      }
      history.replace(`${location.pathname}?page=1${filter}`);
    } else {
      setPageNumber(1);
    }
  }, [siteValue]);

  useComponentDidUpdate(() => {
    if (pageNumber === 1) {
      !!provinceValue || !!status || !!siteValue || (!!fromDate && !!toDate)
        ? onSearch(status, siteValue, provinceValue, 1)
        : fetch(1);

      let filter = "";
      if (!!status) {
        filter += `&status=${status}`;
      }
      if (!!siteValue) {
        filter += `&site=${siteValue}`;
      }
      history.replace(`${location.pathname}?page=1${filter}`);
    } else {
      setPageNumber(1);
    }
  }, [provinceValue]);

  useComponentDidUpdate(() => {
    if (!!fromDate && !!toDate) {
      if (pageNumber === 1) {
        onSearch(status, siteValue, provinceValue, 1);

        let filter = "";
        if (!!status) {
          filter += `&status=${status}`;
        }
        if (!!siteValue) {
          filter += `&site=${siteValue}`;
        }

        history.replace(`${location.pathname}?page=1${filter}`);
      } else {
        setPageNumber(1);
      }
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    !!provinceValue || !!status || !!siteValue || (!!fromDate && !!toDate)
      ? onSearch(status, siteValue, provinceValue, pageNumber)
      : fetch(pageNumber);

    let filter = "";
    if (!!status) {
      filter += `&status=${status}`;
    }

    history.replace(`${location.pathname}?page=${pageNumber}${filter}`);
  }, [pageNumber]);

  const fetch = async (newPage = 1) => {
    setTicketListLoading(true);
    let ticket_list = await api(APIS.ticket + `?page=${newPage}`);

    window.scrollTo(0, 0);
    setTableDataArray(ticket_list?.data);
    setTicketListLoading(false);
  };

  const addTicketHandler = () => {
    navigate(routes["Add Ticket"].path);
  };

  const toDeletepage = async () => {
    let site_list_result = await api(APIS.ticket + `${ticketId}/`, "DELETE", {
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    if (site_list_result.status === 204) {
      toast.error("Ticket deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
    setVisible(false);
    fetch();
    // site_list_result&& props.history.push("/ticket")
  };

  const openDeleteModel = (ticketData) => {
    setVisible(true);
    setTicketId(ticketData.id);
  };

  const finalDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const onSearch = async (value, siteValue, provinceValue, newPage = 1) => {
    console.log("?????????");
    setTicketListLoading(true);
    const body = {};
    console.log("fromDate && !!toDate", fromDate, toDate);
    if (value) {
      body["ticket_status"] = value;
    }
    if (siteValue) {
      body["site_id"] = siteValue;
    }
    if (!!provinceValue) {
      body["province_id"] = provinceValue;
    }

    if (!!fromDate && !!toDate) {
      body["start_date"] = moment(fromDate).format("YYYY-MM-DD");
      body["end_date"] = moment(toDate).format("YYYY-MM-DD");
    }
    let ticket_filter = await api(
      APIS.ticket + `search/?page=${newPage}`,
      "POST",
      body
    );
    setTableDataArray(ticket_filter?.data);
    setTicketListLoading(false);
  };

  const toEditpage = (datas) => {
    navigate({
      pathname: routes["Tickets"].path + "/edit/" + datas.id,
      state: datas,
    });
  };

  const toViewpage = (datas) => {
    navigate({
      pathname: routes["Tickets"].path + "/view/" + datas.id,
      state: datas,
    });
  };

  const toCommentpage = (datas) => {
    navigate({
      pathname: routes["Tickets"].path + "/comment/" + datas.id,
      state: datas,
    });
  };

  const onExportCsv = useCallback(async () => {
    const body = { get_csv: "True" };

    if (!!fromDate && !!toDate) {
      body["start_date"] = moment(fromDate).format("YYYY-MM-DD");
      body["end_date"] = moment(toDate).format("YYYY-MM-DD");
    }
    if (!!siteValue) {
      body["site_id"] = siteValue;
    }
    if (!!status) {
      body["ticket_status"] = status;
    }
    if (!!provinceValue) {
      body["province_id"] = provinceValue;
    }

    setExportTicketListLoading(true);
    let ticket_data_response = await api(`${APIS.ticketCSV}`, "POST", body);
    setExportTicketListLoading(false);

    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "tickets.csv";
    link.href = URL.createObjectURL(
      new Blob([ticket_data_response.data], { type: "text/csv" })
    );
    link.click();
  }, [fromDate, toDate, status, siteValue, provinceValue]);

  return (
    <ContainerBox>
      <Header title="Tickets" />
      {ticketData && (
        <Box
          display="flex"
          flexDirection="row"
          p={1}
          pl={0}
          m={1}
          ml={0}
          bgcolor="background.paper"
        >
          <Box
            p={1}
            bgcolor="grey.300"
            onClick={() => {
              // onSearchHandler(ticketData);
            }}
          >
            Opened Ticket:{ticketData?.opened_count}
          </Box>
          <Box p={1} bgcolor="grey.300" marginLeft="10px">
            Picked Ticket:{ticketData?.picked_count}
          </Box>
          <Box p={1} bgcolor="grey.300" marginLeft="10px">
            Working Ticket:{ticketData?.working_count}
          </Box>
          <Box p={1} bgcolor="grey.300" marginLeft="10px">
            Solved Ticket:{ticketData?.solved_count}
          </Box>
        </Box>
      )}

      <Modal visible={visible} onClose={() => setVisible(false)}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <GrClose
            onClick={() => setVisible(false)}
            style={{ cursor: "pointer" }}
          />
        </div>
        Are you sure you want to delete ticket?
        <div
          style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={() => toDeletepage()}> Delete </Button>
          <Button onClick={() => setVisible(false)}> Cancel</Button>
        </div>
      </Modal>

      <div className="ticket">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div className="filter">
            <div className="formWrapper spacing ">
              <FormLabel label="Date From" className="form">
                <DatePicker
                  showIcon
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="yyyy-MM-dd"
                  openToDate={new Date()}
                  placeholderText="Select Start date"
                />
              </FormLabel>
              {/* </div>
            <div className="formWrapper spacing "> */}
              <FormLabel label="Date To" className="form">
                <DatePicker
                  showIcon
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="yyyy-MM-dd"
                  openToDate={new Date()}
                  placeholderText="Select End date"
                />
              </FormLabel>
            </div>

            <div className="formWrapper spacing ">
              <FormLabel label="Search by Province" className="form">
                <SelectField
                  options={provinceList}
                  getOptionLabel="name"
                  getOptionValue="province_id"
                  onChangeValue={(item) => {
                    // setSiteValue(item?.id);
                    if (item?.province_id) {
                      setProvinceValue(item?.id);
                    } else {
                      setProvinceValue("");
                    }
                  }}
                  // value={provinceList?.find(
                  //   (item) => Number(item.province_id) === Number(siteValue)
                  // )}
                  placeholder="Search By Province"
                  isClearable={true}
                  isSearchable={true}
                />
              </FormLabel>
              <FormLabel label="Search by Site" className="form">
                <SelectField
                  options={siteList}
                  getOptionLabel="name"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    // setSiteValue(item?.id);
                    if (item?.id) {
                      setSiteValue(item?.id);
                    } else {
                      setSiteValue("");
                    }
                  }}
                  value={siteList?.find(
                    (item) => Number(item.id) === Number(siteValue)
                  )}
                  placeholder="Search By Site"
                  isClearable={true}
                  isSearchable={true}
                />
              </FormLabel>
            </div>
            <div className="formWrapper spacing ">
              <FormLabel label="Ticket Status" className="form">
                <SelectField
                  options={TICKETSTATUS}
                  getOptionLabel="label"
                  getOptionValue="value"
                  onChangeValue={(item) => {
                    // setSiteValue(item?.id);
                    if (item?.value) {
                      setStatus(item?.value);
                    } else {
                      setStatus(item?.value);
                      // onChangeHandler("siteValue", "")();
                    }
                  }}
                  value={TICKETSTATUS?.find((item) => item.value === status)}
                  placeholder="Search By Status"
                  isClearable={true}
                  isSearchable={true}
                />
              </FormLabel>
            </div>
            {exportTicketListLoading ? (
              <CircularProgress size={16} />
            ) : (
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={onExportCsv}
              >
                Export
              </Button>
            )}
          </div>

          {userRole === "3" || userRole === "6" ? (
            <div
              style={{
                paddingTop: 20,
              }}
            >
              <Button
                onClick={addTicketHandler}
                variant="contained"
                color="primary"
              >
                Add Ticket
              </Button>
            </div>
          ) : (
            <div />
          )}
        </div>
        <ActivityIndicator animating={ticketListLoading}>
          <MaterialTable
            title="Tickets"
            columns={[
              { title: "ID", field: "id" },
              {
                title: "Date",
                field: "created_at",
                render: (rowData) => finalDate(rowData.created_at),
              },
              { title: "Site", field: "site_name" },
              { title: "Description", field: "description" },
              { title: "Status", field: "status" },
            ]}
            data={tableDataArray?.results}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            actions={[
              (rowData) => {
                return (
                  [6, 3, 1].includes(Number(userRole)) && {
                    icon: "visibility",
                    tooltip: "View Detail",
                    onClick: (event, rowData) => {
                      toViewpage(rowData);
                    },
                  }
                );
              },

              (rowData) => {
                return (
                  userRole === "3" && {
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => {
                      toEditpage(rowData);
                    },
                  }
                );
              },

              (rowData) => {
                return (
                  userRole === "6" && {
                    icon: "edit",
                    tooltip: "Comment",
                    onClick: (event, rowData) => {
                      toCommentpage(rowData);
                    },
                  }
                );
              },

              (rowData) => {
                return (
                  userRole === "3" && {
                    icon: "delete",
                    tooltip: "Delete",
                    onClick: (event, rowData) => {
                      openDeleteModel(rowData);
                    },
                  }
                );
              },
            ]}
            localization={{
              header: {
                actions: userRole === "3" || userRole === "6" ? "Action" : null,
              },
            }}
            options={{
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              search: false,
              paging: false,
              toolbar: false,
              // pageSizeOptions: [5, 10],
              // pageSize: 10
              exportCsv: true,
              exportButton: true,
            }}
            isLoading={ticketListLoading}
          />
        </ActivityIndicator>
        <div style={{ width: "100%", display: "flex" }}>
          <Pagination
            style={{
              marginLeft: "auto",
              marginTop: 20,
              display: "inline-block",
            }}
            count={Math.ceil(tableDataArray?.count / 10)}
            page={Number(pageNumber)}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={page}
          />
        </div>
      </div>
    </ContainerBox>
  );
};

const TICKETSTATUS = [
  { label: "All", value: "all" },
  { label: "Opened", value: "opened" },
  { label: "Picked", value: "picked" },
  { label: "Working", value: "working" },
  { label: "Solved", value: "solved" },
];
export default TicketPage;
