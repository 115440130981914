import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";

import DatePicker from "react-datepicker";

import { api } from "../../../helpers/Api.helper";
import { APIS } from "../../../config/Api.config";
import Pagination from "@material-ui/lab/Pagination";
import { useNavigation } from "react-auth-navigation";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import {
  useComponentDidUpdate,
  useDebounceValue,
  useInput,
} from "../../../hooks";
import { SelectField } from "../../common/selectField/selectfield.common";
import { getParsedUrl } from "../../../helpers/parseUrl.helper";
import moment from "moment/moment";
import { FormLabel } from "../../common/formLabel/formLabel.common";

const UltraResultComponent = () => {
  const { location, history } = useNavigation();

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }

  const { data, onChangeHandler } = useInput({
    siteValue: query.get("site") || "",
    machineId: query.get("machinecode") || "",
    sampleId: query.get("sample") || "",
  });

  const [pageNumber, setPageNumber] = useState(() =>
    query.get("page") ? Number(query.get("page")) : 1
  );

  const [fromDate, setFromDate] = useState(() =>
    query.get("fromDate") ? new Date(query.get("fromDate")) : null
  );
  const [toDate, setToDate] = useState(() =>
    query.get("toDate") ? new Date(query.get("toDate")) : null
  );
  const sampleIdValue = useDebounceValue(data.sampleId);

  const [tableDataArray, setTableDataArray] = useState([]);
  const [siteList, setSiteList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [dateRange, setDateRange] = useState(() => {
    if (!!query.get("fromDate") && !!query.get("toDate")) {
      console.log(query.get("fromDate"));
      let dateRangeFrom = query.get("fromDate");
      let dateRangeTo = query.get("toDate");
      return `&fromDate=${dateRangeFrom}&toDate=${dateRangeTo}`;
    } else return "";
  });

  //FETCH SITE LIST
  useEffect(() => {
    let mount = true;
    if (mount) {
      const fetchSiteList = async () => {
        let site_list_result = await api(APIS.site_list).catch((error) => {});
        setSiteList(site_list_result?.data);
      };
      fetchSiteList();
    }

    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    let mount = true;
    if (mount) {
      searchResult(
        data.siteValue,
        data.machineId,
        sampleIdValue,
        false,
        pageNumber
      );
    }
    return () => {
      mount = false;
    };
  }, [pageNumber]);

  useComponentDidUpdate(() => {
    let mount = true;
    if (mount) {
      if (pageNumber === 1) {
        searchResult(data.siteValue, data.machineId, sampleIdValue, false, 1);
      } else {
        setPageNumber(1);
      }
    }

    return () => {
      mount = false;
    };
  }, [dateRange, data.siteValue, data.machineId, sampleIdValue]);

  useEffect(() => {
    if (!!fromDate && !!toDate) {
      let dateRangeFrom = fromDate
        ? moment(fromDate).format("YYYY-MM-DD")
        : null;
      let dateRangeTo = toDate ? moment(toDate).format("YYYY-MM-DD") : null;
      setDateRange(`&fromDate=${dateRangeFrom}&toDate=${dateRangeTo}`);
    }
  }, [fromDate, toDate]);
  const page = async (event, newPage = 1) => {
    setPageNumber(newPage);
  };

  const searchResult = async (
    bodySiteId,
    bodyMachineId,
    bodySampleId,
    loader,
    newPage = 1
  ) => {
    setIsLoading(true);
    let dateRangeFrom = fromDate ? moment(fromDate).format("YYYY-MM-DD") : null;
    let dateRangeTo = toDate ? moment(toDate).format("YYYY-MM-DD") : null;

    let filter = "";

    if (dateRangeFrom && dateRangeTo) {
      filter += `&fromDate=${dateRangeFrom}&toDate=${dateRangeTo}`;
    }

    if (!!bodySiteId) {
      filter += `&site=${bodySiteId}`;
    }
    if (!!bodyMachineId) {
      filter += `&machinecode=${bodyMachineId}`;
    }
    if (!!bodySampleId) {
      filter += `&sample=${bodySampleId}`;
    }

    history.replace(`${location.pathname}?page=1${filter}`);
    let machine_result = await api(
      APIS.ultra_result +
        `${getParsedUrl({
          page: newPage,
          start_date: dateRangeFrom,
          end_date: dateRangeTo,
          site_id: bodySiteId,
          machine_id: bodyMachineId,
          sample_id: bodySampleId,
        })}`
    );
    if (machine_result?.status === 200) {
      window.scrollTo(0, 0);
      setTableDataArray(machine_result?.data);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  function formatDate(date) {
    if (!date) return null;
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const onDownload = async () => {
    setIsDownloading(true);
    let dateRangeFrom = fromDate ? moment(fromDate).format("YYYY-MM-DD") : null;
    let dateRangeTo = toDate ? moment(toDate).format("YYYY-MM-DD") : null;

    const queryParams = {
      // page: pageNumber,
      site_id: data.siteValue,
      machine_id: data.machineId,
      sample_id: sampleIdValue,
    };
    let filename = "ultraResults.csv";
    if (dateRangeFrom && dateRangeTo) {
      // filter += `&fromDate=${dateRangeFrom}&toDate=${dateRangeTo}`;
      queryParams["start_date"] = dateRangeFrom;
      queryParams["end_date"] = dateRangeTo;

      filename = `ultraResults${dateRangeFrom}to${dateRangeTo}.csv`;
    }

    const response = await api(
      `${APIS.ultra_result}download_csv${getParsedUrl(queryParams)}`
    );
    if (response.status === 200) {
      console.log("response", response);
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = filename;
      link.href = URL.createObjectURL(
        new Blob([response.data], { type: "text/csv" })
      );
      link.click();
      setIsDownloading(false);
    } else {
      setIsDownloading(false);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="resultPage-container">
      <div className="resultPage">
        <form>
          <div className="form-container">
            <div className="formWrapper spacing ">
              <FormLabel label="Date From" className="form">
                <DatePicker
                  showIcon
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="yyyy-MM-dd"
                  openToDate={new Date()}
                  placeholderText="Click to select a date"
                />
              </FormLabel>
              <FormLabel label="Date To" className="form">
                <DatePicker
                  showIcon
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="yyyy-MM-dd"
                  openToDate={new Date()}
                  placeholderText="Click to select a date"
                />
              </FormLabel>
            </div>

            <div className="formWrapper spacing">
              <FormLabel label="Search by Site" className="form">
                <SelectField
                  options={siteList}
                  getOptionLabel="name"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    // setSiteValue(item?.id);
                    if (item?.id) {
                      onChangeHandler("siteValue", item?.id)();
                    } else {
                      onChangeHandler("siteValue", "")();
                      onChangeHandler("machineId", "")();
                    }
                  }}
                  value={siteList?.find(
                    (item) => Number(item.id) === Number(data.siteValue)
                  )}
                  placeholder="Search By Site"
                  isClearable={true}
                  isSearchable={true}
                />
              </FormLabel>
              <FormLabel label="GXMachine" className="form">
                <SelectField
                  options={
                    siteList?.find(
                      (result) => result.id === parseInt(data.siteValue)
                    )?.machines
                  }
                  getOptionLabel="code"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    onChangeHandler("machineId", item?.id)();
                  }}
                  value={
                    data.siteValue && data.machineId
                      ? siteList
                          ?.find(
                            (result) => result.id === parseInt(data.siteValue)
                          )
                          ?.machines?.find(
                            (item) => Number(item.id) === Number(data.machineId)
                          )
                      : null
                  }
                  placeholder="Search By Machine"
                  isClearable={true}
                  isSearchable={true}
                />
              </FormLabel>
            </div>

            <div className="formWrapper spacing">
              <FormLabel label="Sample id" className="form">
                <input
                  style={{ height: 40 }}
                  value={data.sampleId}
                  onChange={(e) =>
                    onChangeHandler("sampleId", e.target.value)()
                  }
                />
              </FormLabel>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: 80,
                }}
              >
                <Button
                  type="button"
                  onClick={onDownload}
                  size="small"
                  style={{ marginLeft: 10 }}
                  disabled={
                    isLoading ||
                    isDownloading ||
                    typeof tableDataArray.results === "undefined"
                  }
                  // disabled={true}
                  variant="contained"
                  color="primary"
                  className="button"
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        </form>
        <>
          {!tableDataArray && isLoading && (
            <div className={classes.root}>
              <CircularProgress />
            </div>
          )}
          <MaterialTable
            columns={[
              { title: "Site", field: "site_name" },
              {
                title: "Instrument Serial Number",
                field: "instrument_serial_number",
              },
              { title: "Module Serial Number", field: "module_serial_number" },
              { title: "Reagent Lot Id", field: "reagent_lot_id" },
              { title: "MTB Result", field: "mtb_result" },
              { title: "RIF Resistance Result", field: "rif_result" },
              { title: "Trace Result", field: "trace_result" },
              { title: "Sample ID", field: "sample_id" },
              {
                title: "Date",
                field: "date",
                render: (rowData) => <div>{formatDate(rowData.date)}</div>,
              },
              {
                title: "Error",
                field: "error",
                render: (rowData) => (
                  <div className="tooltip">
                    {rowData.error.slice(0, 10)}{" "}
                    {/* <span className="tooltiptext">{rowData.error}</span> */}
                  </div>
                ),
              },

              {
                title: "Error Desc.",
                field: "error",
                render: (rowData) => (
                  <div className="tooltip" style={{ width: 150 }}>
                    {rowData.error.slice(11)}{" "}
                    {/* <span className="tooltiptext">{rowData.error}</span> */}
                  </div>
                ),
              },
            ]}
            data={tableDataArray?.results}
            isLoading={isLoading}
            options={{
              toolbar: false,
              filtering: true,
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              search: false,
              pageSizeOptions: [5, 10],
              pageSize: 10,
              paging: false,
            }}
          />
        </>

        <div style={{ width: "100%", display: "flex" }}>
          <Pagination
            style={{
              marginLeft: "auto",
              marginTop: 20,
              display: "inline-block",
            }}
            count={Math.ceil(tableDataArray?.count / 100)}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={page}
          />
        </div>
      </div>
    </div>
  );
};

export default UltraResultComponent;
