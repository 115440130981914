import React, { useEffect, useState } from "react";
import { APIS } from "../../config/Api.config";
import { api } from "../../helpers/Api.helper";
import { useNavigation } from "react-auth-navigation";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import Header from "../common/header/Header.common";
import moment from "moment";
import GraphBox from "./graph/GraphBox";
import { ActivityIndicator } from "../common/hoc";
import { useComponentDidUpdate } from "../../hooks";
import { GraphComponent, MachineCard } from "./components";

const GxSiteDetailPage = () => {
  const { params } = useNavigation();
  const [gxDetail, setGxDetail] = useState();
  const [lineChartData, setLineChartData] = useState([]);

  const [siteChartLoading, setSiteChartLoading] = useState(false);
  const [siteStats, setSiteStats] = useState([]);

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const fetchDetail = async () => {
    let site_result = await api(APIS.sites_response + params.id, "", "");
    setGxDetail(site_result?.data);
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  useEffect(() => {
    let mount = true;
    const fetchReport = async () => {
      const response = await api(APIS.chartData, "POST", {
        site_id: params.id,
      });
      if (mount) {
        let newArray = [];
        for (let i = 0; i < response?.data.ALL_RESULTS?.length; i++) {
          newArray.push({
            Month: moment(response?.data.ALL_RESULTS[i].month).format(
              "MMM YYYY"
            ),
            "ALL RESULTS": response?.data.ALL_RESULTS[i].total,
            ERROR: response?.data.ERROR[i].total,
            INVALID: response?.data.INVALID[i].total,
            "MTB NOT DETECTED": response?.data.MTB_NOT_DETECTED[i].total,
            "MTB RIF I": response?.data.MTB_RIF_I[i].total,
            "MTB RIF R": response?.data.MTB_RIF_R[i].total,
            "MTB RIF S": response?.data.MTB_RIF_S[i].total,
            "RESULT NOT FOUND": response?.data.NO_RESULT[i].total,
          });
        }
        setLineChartData(newArray.reverse());
      }
    };
    fetchReport();

    return () => (mount = false);
  }, []);

  useEffect(() => {
    (async () => {
      setSiteChartLoading(true);
      let site_result = await api(`${APIS.sitenotworkingdata}/${params.id}/`);

      if (site_result?.status === 200) {
        setSiteStats(site_result?.data);
        setSiteChartLoading(false);
      }
      setSiteChartLoading(false);
      // setGxDetail(site_result?.data);
    })();
  }, [params?.id]);

  useComponentDidUpdate(() => {
    console.log("useComponentDidUpdate");
    if (!fromDate || !toDate) return;
    console.log("useComponentDidUpdate1111111111111111");
    (async () => {
      setSiteChartLoading(true);

      const body = { from_date: fromDate, to_date: toDate };

      let site_result = await api(
        `${APIS.sitenotworkingdata}/${params.id}/`,
        "POST",
        body
      );

      console.log("site_result", site_result);
      if (site_result?.status === 200) {
        setSiteStats(site_result?.data);
        setSiteChartLoading(false);
      }
      setSiteChartLoading(false);
      // setGxDetail(site_result?.data);
    })();
  }, [params?.id, fromDate, toDate]);

  console.log("fromDate", fromDate);
  return (
    <ContainerBox>
      <Header title="Gx Site Detail" />
      <div className="gxdetail-container">
        <div className="gxdetail">
          <div className="gxdetail-map">
            {gxDetail && (
              <MapContainer
                center={[gxDetail.latitude, gxDetail.longitude]}
                zoom={15}
                zoomControl={true}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <Marker
                  position={[gxDetail.latitude, gxDetail.longitude]}
                  eventHandlers={{
                    mouseover: (e) => {
                      e.target.openPopup();
                    },
                    mouseout: (e) => {
                      e.target.closePopup();
                    },
                  }}
                >
                  <Popup>{gxDetail.name}</Popup>
                </Marker>
              </MapContainer>
            )}
          </div>

          <div className="gxdetail-graph">
            <h3>MTB Results Last 6 months</h3>
            {lineChartData.length > 0 ? (
              <GraphBox data={lineChartData} />
            ) : (
              <div className="gxdetail-graph-loading">
                {" "}
                <ActivityIndicator animating={true} />
              </div>
            )}
          </div>
          <div className="gxdetail-graph">
            <GraphComponent
              // onClose={() => setMachineGraphVisible(false)}
              loading={siteChartLoading}
              data={siteStats}
              graphType="Site"
              {...{ fromDate, setFromDate, toDate, setToDate }}
            />
          </div>

          <div className="gxdetail-site">
            <div className="gxdetail-site-name">{gxDetail?.name}</div>

            <div className="gxdetail-site-contact">
              <div className="gxdetail-site-contact-name">
                Average Test per Day
              </div>
              <div className="gxdetail-site-contact-number">
                {Math.floor(gxDetail?.avg_result_per_day ?? 0)}
              </div>
              <div className="gxdetail-site-contact-name">Contact Person</div>
              <div className="gxdetail-site-contact-number">
                {gxDetail?.contactName}
              </div>

              <div className="gxdetail-site-contact-name">Contact Number</div>
              <div className="gxdetail-site-contact-number">
                {gxDetail?.contactPhone}
              </div>

              <div className="gxdetail-site-contact-name">
                Result Last Uploaded On
              </div>
              <div className="gxdetail-site-contact-number">
                {formatDate(gxDetail?.updated_at)}
              </div>
            </div>
          </div>

          <div className="gxdetail-machines">
            <div className="gxdetail-machines-item">
              <div>
                <strong>Machine Serial number</strong>
              </div>
              <div className="gxdetail-machines-item-module">
                <strong>Module Numbers</strong>
              </div>
            </div>

            {gxDetail?.machines?.map((item, index) => {
              return <MachineCard {...{ item }} key={item.id} />;
            })}
          </div>
        </div>
      </div>
    </ContainerBox>
  );
};

export default GxSiteDetailPage;
