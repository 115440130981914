import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ContainerBox from "../../common/hoc/ContainerBox.hoc";
import { Button } from "@material-ui/core";
import { api } from "../../../helpers/Helpers";
import { APIS } from "../../../config/Api.config";
import { ActivityIndicator } from "../../common/hoc";
import Header from "../../common/header/Header.common";
import { useNotification } from "../../../context";
import { SelectField } from "../../common/selectField/selectfield.common";

const AddTicket = (props) => {
  const { onNotificationHandler } = useNotification();

  const { register, handleSubmit, errors, watch } = useForm();
  const siteWatch = watch("site");
  const [siteList, setSiteList] = useState([]);
  const [ticketLoader, setTicketLoader] = useState(false);
  const [getUserList, setGetUserList] = useState([]);

  const [siteData, setSiteData] = useState();
  const [machineId, setMachineId] = useState();
  const [assignTo, setAssignTo] = useState();

  useEffect(() => {
    let mount = true;
    const fetch = async () => {
      let site_list_result = await api(APIS.site_list);
      if (mount && site_list_result.status === 200) {
        setSiteList(site_list_result?.data);
      }
    };
    fetch();
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    let mount = true;
    const onGetUserHandler = async (id) => {
      let userList = [];
      let res = await api(APIS.getSiteUsers, "POST", { site: id });

      if (res?.data[3]?.length > 0) {
        for (let i = 0; i < res?.data[3]?.length; i++) {
          userList.push(res?.data[3][i]);
        }
      }
      // if (res?.data[6]?.length > 0) {
      //     for (let i = 0; i < res?.data[1]?.length; i++) {
      //         userList.push(res?.data[6][i])
      //     }
      // }
      if (mount) {
        setGetUserList(userList.filter(Boolean));
      }
    };

    if (siteWatch) {
      onGetUserHandler(siteWatch);
    }
    return () => {
      mount = false;
    };
  }, [siteWatch]);

  const onSubmit = async (data) => {
    setTicketLoader(true);
    let add_ticket_result = await api(APIS.ticket, "POST", {
      description: data.description,
      site: siteData,
      machine: machineId,
    });

    if (add_ticket_result.status === 201) {
      onNotificationHandler();
      toast.success("Ticket Added!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      if (!!assignTo) {
        let addAssignedTo = await api(
          `${APIS.ticket}${add_ticket_result?.data?.id}/`,
          "PATCH",
          {
            assigned_to: assignTo,
          }
        );

        if (addAssignedTo.status === 200) {
          setTicketLoader(false);
          onNotificationHandler();
          toast.success("Ticket Assigned!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          props.history.goBack();
        }
        return;
      }

      props.history.goBack();
    }
    setTicketLoader(false);
  };

  console.log("getUserList", getUserList);
  return (
    <ContainerBox>
      <Header title="Add Ticket" />
      <div className="addticket">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addticket-form">
            <div className="editmachinestatus-form-site form">
              <label>GX-SITE</label>
              <SelectField
                options={siteList}
                getOptionLabel="name"
                getOptionValue="id"
                onChangeValue={(item) => {
                  console.log("siteData", item);
                  setSiteData(item?.id);
                }}
                value={siteList?.find((item) => item.id === siteData)}
                placeholder="Select GxSite"
                isClearable={true}
                isSearchable={true}
              />

              {/* {siteList.length > 0 ? (
                <select
                  name="site"
                  ref={register({ required: true })}
                  className={`${errors.site && "showerror"}`}
                >
                  <option value="">Select Site</option>
                  {siteList.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select className={`${errors.site && "showerror"}`}>
                  <option value="">Select Site</option>
                </select>
              )} */}
            </div>

            <div className="editmachinestatus-form-site form">
              <label>GXMachine</label>
              <SelectField
                options={
                  siteList?.find((result) => result.id === parseInt(siteData))
                    ?.machines
                }
                getOptionLabel="code"
                getOptionValue="id"
                onChangeValue={(item) => {
                  console.log("siteData", item);
                  setMachineId(item?.id);
                }}
                value={siteList
                  ?.find((result) => result.id === parseInt(siteData))
                  ?.machines?.find((item) => item.id === machineId)}
                placeholder="Select GxSite"
                isClearable={true}
                isSearchable={true}
              />

              {/* {siteList.length > 0 ? (
                <select
                  name="machine"
                  ref={register({ required: true })}
                  className={`${errors.product_model && "showerror"}`}
                >
                  <option value="">Select Machine</option>
                  {siteWatch &&
                    siteList
                      .find((result) => result.id === parseInt(siteWatch))
                      .machines.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.code}
                          </option>
                        );
                      })}
                </select>
              ) : (
                <select className={`${errors.site && "showerror"}`}>
                  <option value="">Select Machine</option>
                </select>
              )} */}
            </div>

            <div className="editmachinestatus-form-site form">
              <label> Assign To</label>

              <SelectField
                options={getUserList}
                getOptionLabel="username"
                getOptionValue="id"
                onChangeValue={(item) => {
                  setAssignTo(item?.id);
                }}
                // value={getUserList?.find((item) => item.id === assignTo)}
                placeholder="Select GxSite"
                isClearable={true}
                isSearchable={true}
              />
              {/* //   <select
              //     name="user"
              //     ref={register({ required: true })}
              //     className={`${errors.user && "showerror"}`}
              //   >
              //     <option value="">Select User</option>
              //     {getUserList.map((item, index) => (
              //       <option value={item.id} key={index}>
              //         {item.username}
              //       </option>
              //     ))}
              //   </select>
              // ) : (
              //   <select className={`${errors.user && "showerror"}`}>
              //     <option value="">Select User </option>
              //   </select>
              // )
            // } */}
            </div>

            <div className="addgxsite-form-name form">
              <label>Description</label>
              <textarea
                rows="5"
                name="description"
                ref={register({ required: true })}
                className={`${errors.name && "showerror"} `}
              />
            </div>

            <div className="addgxsite-form-row">
              <ActivityIndicator animating={ticketLoader}>
                <Button type="submit" variant="contained" color="primary">
                  submit
                </Button>
              </ActivityIndicator>
            </div>
          </div>
        </form>
      </div>
    </ContainerBox>
  );
};

export default AddTicket;
