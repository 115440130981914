import React from "react";

const DashboardDetailPage = () => {
  return (
    <div className="dashboarddetail-container">
          <div className="dashboarddetail">
              Dashboard detail Page

          </div>
    </div>
  )
}

export default DashboardDetailPage;