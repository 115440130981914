import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { FormLabel } from "../../common/formLabel/formLabel.common";
import { Button } from "@material-ui/core";
import moment from "moment";
import { api } from "../../../helpers/Api.helper";
import { APIS } from "../../../config/Api.config";
import { toast } from "react-toastify";

export const UpdateCalliberationDate = ({
  detail,
  onClose,
  warrantyDate,
  setWarrentyDate,
  calliberationDate,
  setCalliberationDate,
}) => {
  const [warrantyEndDate, setWarrentyEndDate] = useState(() =>
    warrantyDate ? new Date(warrantyDate) : ""
  );
  const [calliberationEndDate, setCalliberationEndDate] = useState(() =>
    calliberationDate ? new Date(calliberationDate) : ""
  );

  const onUpdateCalliberationDate = async () => {
    let body = {
      // warranty_end_date: moment(warrantyEndDate).format("YYYY-MM-DD"),
      // calibration_date: moment(calliberationEndDate).format("YYYY-MM-DD"),
    };

    if (!!warrantyEndDate) {
      body["warranty_end_date"] = moment(warrantyEndDate).format("YYYY-MM-DD");
    }
    if (!!calliberationEndDate) {
      body["calibration_date"] =
        moment(calliberationEndDate).format("YYYY-MM-DD");
    }

    let machine_post_result = await api(
      APIS.machineserialno + `${detail.id}/`,
      "PATCH",
      body
    );

    if (machine_post_result.status === 200) {
      toast.success("Date Updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      //   navigate({ pathname: routes["GX Machine"].path });

      setWarrentyDate(warrantyEndDate);
      setCalliberationDate(calliberationEndDate);
      onClose();
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            marginBottom: 20,
          }}
        >
          <h2>Update Date</h2>
        </div>
        <label>
          Serial : {detail?.serial_no} {`  `}
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: 20,
          }}
        >
          <div style={{ width: "100%" }}>
            <FormLabel label="GX Warranty End Date" className="form">
              <DatePicker
                showIcon
                selected={warrantyEndDate}
                onChange={(date) => setWarrentyEndDate(date)}
                dateFormat="yyyy-MM-dd"
                openToDate={new Date()}
                placeholderText="Click to select a date"
              />
            </FormLabel>
          </div>
          <div style={{ width: "100%" }}>
            <FormLabel label="Calliberation Date" className="form">
              <DatePicker
                showIcon
                selected={calliberationEndDate}
                onChange={(date) => setCalliberationEndDate(date)}
                dateFormat="yyyy-MM-dd"
                openToDate={new Date()}
                placeholderText="Click to select a date"
              />
            </FormLabel>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
          columnGap: 20,
        }}
      >
        <Button
          color="default"
          style={{ background: "red", color: "white" }}
          onClick={onClose}
        >
          {" "}
          Close{" "}
        </Button>

        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={onUpdateCalliberationDate}
        >
          submit
        </Button>
      </div>
    </div>
  );
};
