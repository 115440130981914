import React, { useState, useEffect } from "react";
import { Modal } from "react-uicomp";
import { useNavigation, useAuth } from "react-auth-navigation";

import { useForm } from "react-hook-form";
import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Api.helper";
import { Button } from "@material-ui/core";
import { GrClose } from "react-icons/all";
import { ActivityIndicator } from "../../common/hoc";
import { useNotification } from "../../../context";
const DisplayDataBox = ({ data, title, toast, loading }) => {
  const { onNotificationHandler } = useNotification();
  const { userId, userRole } = useAuth();
  const { navigation } = useNavigation();
  const { routes, navigate } = navigation;
  const { register, handleSubmit, errors, setValue } = useForm();
  const [visible, setVisible] = useState(false);
  const [siteId, setSiteId] = useState();
  const [machineId, setMachineId] = useState();
  const [getUserList, setGetUserList] = useState([]);

  const directToEdit = (datas) => {
    navigate(routes["Machine Status"].path + "/edit/" + datas?.ticket?.id);
  };

  const onTicketCreate = async (data) => {
    // console.warn("item",data,siteId);
    let createTicket = await api(APIS.ticket, "POST", {
      description: data.description,
      site: siteId,
      machine: machineId,
      // status: "opened",
      // created_by: userId,
      // assigned_to: data.user,
    });

    if (createTicket.status === 201) {
      onNotificationHandler();
      toast();
      setVisible(false);
    }
  };

  const openModel = async (item) => {
    console.log("<<<<<<<<<<<<<<<<<<<<<<<", item);
    setVisible(true);
    setSiteId(item?.site);
    setMachineId(item?.id);
    let userList = [];
    let res = await api(APIS.getSiteUsers, "POST", { site: item?.site });
    if (res?.data[3]?.length > 0) {
      for (let i = 0; i < res?.data[3]?.length; i++) {
        userList.push(res?.data[3][i]);
      }
    }
    if (res?.data[6]?.length > 0) {
      for (let i = 0; i < res?.data[1]?.length; i++) {
        userList.push(res?.data[6][i]);
      }
    }

    setGetUserList(userList.filter(Boolean));
  };
  const seperatePageHandler = (title) => {
    if (title === "Machine Running Today") {
      navigate(routes["Running Today"].path);
    } else if (title === "Delay More than 7 days") {
      navigate(routes["Delay Seven"].path);
    } else if (title === "Delay for 5-6 days") {
      navigate(routes["Delay Five Six"].path);
    } else if (title === "Delay for 3-4 days") {
      navigate(routes["Delay Three Four"].path);
    } else if (title === "Delay for 1-2 days") {
      navigate(routes["Delay One Two"].path);
    }
  };

  return (
    <div className="delay">
      {/* <ActivityIndicator */}

      <Modal visible={visible} onClose={() => setVisible(false)}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <GrClose
            onClick={() => setVisible(false)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <form onSubmit={handleSubmit(onTicketCreate)}>
          <div className="form">
            <label> Assign To</label>
            {getUserList.length > 0 && (
              <select
                name="user"
                ref={register({ required: true })}
                className={`${errors.user && "showerror"}`}
              >
                <option value=""> select</option>
                {getUserList.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.username}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="addgxsite-form-name form">
            <label>Description</label>
            <textarea
              rows="5"
              name="description"
              ref={register({ required: true })}
              className={`${errors.name && "showerror"} `}
            />
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="submit" color="primary" variant="contained">
              {" "}
              Create Ticket{" "}
            </Button>
            <Button onClick={() => setVisible(false)} variant="contained">
              {" "}
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
      <h2
        className="delay-title"
        onClick={() => {
          seperatePageHandler(title);
        }}
      >
        {title}
      </h2>
      <ActivityIndicator animating={loading}>
        <div style={{}} className="delay-list">
          {data &&
            data.map((item, index) => {
              return (
                <div key={index}>
                  <div className="delay-list-row">
                    <div
                      className={"delay-list-row-cursor-hover"}
                      onClick={() =>
                        navigate(routes["GX Sites"].path + "/" + item.site)
                      }
                    >
                      <span>{item?.site_name}</span>: {item?.serial}
                    </div>
                    {item?.ticket ? (
                      <div className="delay-list-row-content">
                        <div style={{ display: "flex" }}>
                          <div className="delay-list-row-content-status">
                            Status: <span>{item?.ticket?.status}</span>
                          </div>
                          {item?.ticket?.description && (
                            <div className="delay-list-row-content-description">
                              Description:{" "}
                              <span>{item?.ticket?.description}</span>
                            </div>
                          )}
                        </div>
                        <div>
                          {(userRole === "3" || userRole === "6") && (
                            <div
                              className="delay-list-row-content-edit"
                              onClick={() => directToEdit(item)}
                            >
                              Edit Ticket
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {(userRole === "3" || userRole === "6") && (
                          <div
                            className="delay-list-row-create"
                            onClick={() => openModel(item)}
                          >
                            Create Ticket
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <hr style={{ marginTop: 5 }} />
                </div>
              );
            })}
        </div>
      </ActivityIndicator>
    </div>
  );
};

export default DisplayDataBox;
