import React from "react";
import { ActivityIndicator } from "../../common/hoc";

const ShowDataCard = (props) => {
  return (
    <div
      onClick={() => {
        props.clicked(props.title);
      }}
      className="showdatacard"
    >
      <h3 className="showdatacard-title">{props.title}</h3>
      <h1 className="showdatacard-data">{props.data}</h1>
    </div>
  );
};

export const DisplayDataCard = (props) => {
  return (
    <div
      onClick={() => {
        props.clicked(props.title);
      }}
      className="displaydatacard"
    >
      <h3 className="displaydatacard-title">{props.title}</h3>
      <h1 className="displaydatacard-data">{props.data}</h1>
    </div>
  );
};

export const AnalysisDataCard = (props) => {
  return (
    <div className="showdatacard">
      <h3 className="showdatacard-title">{props.title}</h3>
      <ActivityIndicator animating={props.loading}>
        <h1 className="showdatacard-data">
          {typeof props?.data === "number"
            ? `${props?.data?.toFixed(2)} %`
            : props?.data}
        </h1>
      </ActivityIndicator>
    </div>
  );
};

export const ShowListCard = ({ title, data }) => {
  // console.log("List ",data)\

  //Calibrating Machine API is inconsistent with other API.

  if (title === "GX machine that needs calibration in 6 months") {
    return (
      <div className="showlistcard">
        <h3 className="title">{title}</h3>

        {data?.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: 300,
              overflowY: "auto",
            }}
          >
            {data.map((element, index) => {
              return (
                <p key={index} className="showlistcard-data">
                  {element.site_name}:{element.code}:{element.serial_no} <br/>
                  <span style={{fontSize:"14px"}}>Calibration Date: {element.calibration_date}</span>
                </p>
              );
            })}
          </div>
        ) : (
          <div>No data here</div>
        )}
      </div>
    );
  } else if (title === "GX machine warranty expiring in 6 months") {
    return (
      <div className="showlistcard">
        <h3 className="title">{title}</h3>

        {data?.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: 300,
              overflowY: "auto",
            }}
          >
            {data.map((element, index) => {
              return (
                <p key={index} className="showlistcard-data">
                  {element.site_name}:{element.code}: S.N.{element.serial_no}<br/>
                  <span style={{fontSize:"14px"}}>Expiring in: {element.warranty_end_date}</span>
                </p>
              );
            })}
          </div>
        ) : (
          <div>No data here</div>
        )}
      </div>
    );
  } else {
    return (
      <div className="showlistcard">
        <h3 className="title">{title}</h3>
        {data?.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: 300,
              overflowY: "auto",
            }}
          >
            {data.map((element, index) => {
              return (
                <p className="showlistcard-data" key={index}>
                  {element.site?.name}:{element.code}
                </p>
              );
            })}
          </div>
        ) : (
          <div>No data</div>
        )}
      </div>
    );
  }
};
export default ShowDataCard;
