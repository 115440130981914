import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const GraphBox = (props) => {
  const { data } = props;
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ResponsiveContainer height={500}>
        <LineChart
          // width={width}
          // height={height}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="Month" />
          <YAxis />
          <Tooltip />
          <Legend iconType="circle" />
          <Line
            type="monotone"
            dataKey="ALL RESULTS"
            stroke="#003f5c"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="INVALID"
            stroke="#05c46b"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="ERROR"
            stroke="#890a0d"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="MTB NOT DETECTED"
            stroke="#F79F1F"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="MTB RIF I"
            stroke="#0fbcf9"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="MTB RIF R"
            stroke="#EE5A24"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="MTB RIF S"
            stroke="#A3CB38"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="RESULT NOT FOUND"
            stroke="#005ff1"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GraphBox;
