import React, { useState } from "react";
import { Auth, withNavigation } from "react-auth-navigation";
import { PRIVATE_PATHS, PUBLIC_PATHS } from "./Paths.app";
import { USER_ROLES } from "./UserRoles.app";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from "react-toastify";
// Components
import SideNavigation from "../common/sidenav/Sidenav.common";
import { NotificationContextProvider } from "../../context";
// Export App with Auth Provider and Auth Screens inside it.z

// Export App with Auth Provider and Auth Screens inside it.z
const MemoChild = ({ children }) => {
  const isLoggedIn = localStorage.getItem("isLoggedin") ? true : false;
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const user_type = localStorage.getItem("userRole")
    ? localStorage.getItem("userRole")
    : "user";
  const [config, setConfig] = useState({
    isLoggedIn,
    userRole: user_type,
    token: token,
    showSidebar: true,
    userId: userId,
  });
  const [sideNavVisible, setSideNavVisible] = useState(true);
  const [fullPageLoaderVisible, setFullpageLoaderVisible] = useState(false);
  return (
    // <Navigation.Provider
    //   privatePaths={PRIVATE_PATHS}
    //   publicPaths={PUBLIC_PATHS}
    //   userRoles={USER_ROLES}
    // >
    <NotificationContextProvider>
      <Auth
        config={config}
        state={{
          sideNavVisible,
          setSideNavVisible,
          setFullpageLoader: (val) => setFullpageLoaderVisible(val),
          handleLogin: (token, id, user_type) => {
            localStorage.setItem("isLoggedin", true);
            localStorage.setItem("token", token);
            localStorage.setItem("userId", id);
            localStorage.setItem("userRole", user_type);
            setConfig({
              ...config,
              isLoggedIn: true,
              userRole: user_type,
              userId: id,
              token: token,
            });
          },
          handleLogout: () => {
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userRole");
            setConfig({
              ...config,
              isLoggedIn: false,
              userRole: "user",
              token: null,
              userId: null,
            });
          },
          togglenav: (data) => {
            setConfig({ ...config, showSidebar: !data });
          },
        }}
      >
        {children}
      </Auth>
    </NotificationContextProvider>
    // </Navigation.Provider>
  );
};

const App = () => {
  return (
    // <Navigation.Provider
    //   privatePaths={PRIVATE_PATHS}
    //   publicPaths={PUBLIC_PATHS}
    //   userRoles={USER_ROLES}
    // >
    <MemoChild>
      <>
        <SideNavigation />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />

        <Auth.Screens />
      </>
    </MemoChild>
    // </Navigation.Provider>
  );
};

// export default App;

export default withNavigation(App, {
  publicPaths: PUBLIC_PATHS,
  privatePaths: PRIVATE_PATHS,
  userRoles: USER_ROLES,
  routerType: "hash",
});
