import React, { useState, useEffect } from "react";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import Header from "../common/header/Header.common";
import { XDRResultComponent } from "./result/xdrResultComponent";

const XDRResultPage = () => {
  const [mount, setMount] = useState(true);
  useEffect(() => {
    return () => {
      setMount(false);
    };
  }, []);
  return (
    <ContainerBox>
      <Header title="XDR Results" />
      <div className="result">{mount && <XDRResultComponent />}</div>
    </ContainerBox>
  );
};
export default XDRResultPage;
