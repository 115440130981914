import React, { useState, useEffect } from "react";
import { useNavigation, useAuth } from "react-auth-navigation";
import DateFnsUtils from "@date-io/date-fns";
import { api } from "../../helpers/Api.helper";
import { APIS } from "../../config/Api.config";
import ShowDataCard, {
  AnalysisDataCard,
  ShowListCard,
} from "./showDataBox/ShowDataBox";
import NepalMap from "./nepalMap/NepalMap";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import Header from "../common/header/Header.common";
import GraphBox from "./graph/GraphBox";
import PieChartBox from "./graph/PieChartBox";
import { ActivityIndicator } from "../common/hoc";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { SelectField } from "../common/selectField/selectfield.common";
import { useComponentDidUpdate } from "../../hooks";
import { ShowTable } from "./showDataTable/ShowDataTable";

export const LEVEL = [
  { name: "District", value: "district" },
  { name: "Province", value: "province" },
  { name: "National", value: "national" },
];

export const RESULTTYPES = [
  { name: "MTB/RIF", value: "MTB/RIF" },
  { name: "ULTRA", value: "ULTRA" },
];

const DashboardPage = () => {
  const { navigation } = useNavigation();
  const { userId } = useAuth();
  const { navigate, routes } = navigation;
  const [totalMachineResults, setTotalMachineResults] = useState();
  const [totalGxMachine, setTotalGxMachine] = useState();
  const [totalGxSites, setTotalGxSites] = useState();
  const [totalEnrolledSites, setTotalEnrolledSites] = useState();
  const [dataCount, setDataCount] = useState();
  const [expiringMachine, setExpiringMachine] = useState();
  const [calibrationMachine, setCalibrationMachine] = useState([]);
  const [onlineMachine, setOnlineMachine] = useState();
  const [ticketData, setTicketData] = useState();

  const [covidData, setCovidData] = useState();
  const [lineChartData, setLineChartData] = useState([]);
  const [machineStatus, setMachineStatus] = useState();
  const [isMtb, setIsMtb] = useState(false);
  const [isCovid, setIsCovid] = useState(false);

  const [dashboardAnalysis, setDashboardAnalysis] = useState();
  const [siteList, setSiteList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [siteValue, setSiteValue] = useState("");
  const [level, setLevel] = useState("");
  const [district, setDistrict] = useState("");
  const [province, setProvince] = useState("");
  const [loading, setLoading] = useState(false);
  const [resultType, setResultType] = useState("MTB/RIF");

  const siteRef = React.useRef();
  const provinceRef = React.useRef();
  const districtRef = React.useRef();

  useEffect(() => {
    let mount = true;
    const fetchUserData = async () => {
      let res = await api(APIS.users + userId + "/");
      if (mount) {
        setIsMtb(res.data.is_mtb_results);
        setIsCovid(res?.data?.is_covid_results);
      }
    };
    fetchUserData();
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    let mount = true;
    async function fetch() {
      let total_machine_result_response = await api(APIS.total_machine_results);
      let total_gx_machines_response = await api(APIS.total_gx_machines);
      let total_gx_response = await api(APIS.total_gx_counts);
      let expiring_machine_response = await api(APIS.expiring_machine);
      let calibration_machine_response = await api(APIS.calibration_machine);
      let machine_online_response = await api(APIS.machine_online);
      let report_data = await api(APIS.get_report_data, "POST");
      if (mount) {
        setTotalMachineResults(total_machine_result_response?.data?.count);
        setTotalGxMachine(total_gx_machines_response?.data?.count);
        setTotalGxSites(total_gx_response?.data?.total_count);
        setTotalEnrolledSites(total_gx_response?.data?.enrolled_count);
        setCalibrationMachine(calibration_machine_response?.data);
        setExpiringMachine(expiring_machine_response?.data);
        setDataCount(report_data?.data);
        setOnlineMachine(machine_online_response?.data.length);
      }
    }
    fetch();

    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    let mount = true;
    const fetchReport = async () => {
      const response = await api(APIS.get_covid_report_data, "POST", {
        end_date: moment().format("YYYY-MM-DD"),
        start_date: "2020-02-01",
      });
      if (mount) {
        setCovidData(response?.data);
      }
    };
    fetchReport();

    return () => (mount = false);
  }, []);

  useEffect(() => {
    let mount = true;
    const fetch_ticket_data = async () => {
      let ticket_data_response = await api(APIS.ticket_data);
      if (mount) {
        setTicketData(ticket_data_response?.data);
      }
    };
    fetch_ticket_data();

    return () => (mount = false);
  }, []);

  useEffect(() => {
    let mount = true;
    const fetchReport = async () => {
      const response = await api(APIS.chartData, "POST");
      if (mount) {
        let newArray = [];
        for (let i = 0; i < response?.data.ALL_RESULTS?.length; i++) {
          newArray.push({
            Month: moment(response?.data.ALL_RESULTS[i].month).format(
              "MMM YYYY"
            ),
            "ALL RESULTS": response?.data.ALL_RESULTS[i].total,
            ERROR: response?.data.ERROR[i].total,
            INVALID: response?.data.INVALID[i].total,
            "MTB NOT DETECTED": response?.data.MTB_NOT_DETECTED[i].total,
            "MTB RIF I": response?.data.MTB_RIF_I[i].total,
            "MTB RIF R": response?.data.MTB_RIF_R[i].total,
            "MTB RIF S": response?.data.MTB_RIF_S[i].total,
            "RESULT NOT FOUND": response?.data.NO_RESULT[i].total,
          });
        }
        setLineChartData(newArray.reverse());
      }
    };
    fetchReport();

    return () => (mount = false);
  }, []);

  //machine status
  useEffect(() => {
    let mount = true;
    const initialFetch = async () => {
      let machine_status_response = await api(APIS.machine_status_result);
      if (mount) {
        setMachineStatus(machine_status_response?.data);
      }
    };

    initialFetch();
    return () => {
      mount = false;
    };
  }, []);

  //FETCH SITE LIST
  useEffect(() => {
    const fetchSiteList = async () => {
      let site_list_result = await api(APIS.site_list).catch((error) => {
        console.log(site_list_result.error);
      });
      setSiteList(site_list_result?.data);
    };
    fetchSiteList();
  }, []);

  const onClickedHandler = (title) => {
    if (title === "Total Machine Results") {
      navigate({
        pathname: routes["Results"].path,
      });
    } else if (title === "Total No of GX Machines") {
      navigate(routes["GX Machine"].path);
    } else if (title === "Total GX Sites") {
      navigate({
        pathname: routes["GX Sites"].path,
      });
    } else if (title === "GX Machine Running Today") {
      navigate({
        pathname: routes["Running Today"].path,
      });
    }
  };

  useEffect(() => {
    fromDate && toDate && filterDashboardData(siteValue, province, district);
  }, [fromDate, toDate]);

  useEffect(() => {
    resultType && filterDashboardData(siteValue, province, district);
  }, [resultType]);

  useComponentDidUpdate(() => {
    if (district) {
      console.log("district");
      level === "district" && filterDashboardData(null, null, district);

      onClearSiteData();
    }
  }, [district]);

  useComponentDidUpdate(() => {
    if (province) {
      console.log("province");
      if (level === "province") {
        filterDashboardData(null, province);
      }

      onClearSiteData();
      onClearDistrictData();
    }
  }, [province]);

  useComponentDidUpdate(() => {
    // if (siteValue) {
    console.log("siteValue", siteValue);
    filterDashboardData(siteValue);

    onClearDistrictData();
    onClearProvinceData();
    // }
  }, [siteValue]);

  useEffect(() => {
    (async () => {
      const res = await api(APIS.get_province);

      setProvinceList(res?.data?.results);
    })();
  }, []);

  useComponentDidUpdate(() => {
    (async () => {
      if (!!province) {
        const res = await api(`${APIS.get_district}${province}`);

        setDistrictList(res?.data);
      }
    })();
  }, [province]);

  const filterDashboardData = React.useCallback(
    async (siteValue, province, district) => {
      console.log(
        "siteValue, province, district",
        siteValue,
        province,
        district
      );
      setLoading(true);
      let body = {
        test_type: resultType,
      };
      if (fromDate && toDate) {
        body.start_date = fromDate;
        body.end_date = toDate;
      }
      if (level === "district" && !!district) {
        body.district_id = district;
      }
      if (level === "province" && !!province) {
        body.province_id = province;
      }
      if (siteValue) {
        body.site_id = siteValue;
      }
      console.log("body", body);
      let dashboardData = await api(APIS.dashboardAnalysis, "POST", body);

      console.log("dashboardData", dashboardData);
      if (dashboardData?.status === 200) {
        setDashboardAnalysis(dashboardData?.data);
      }
      setLoading(false);
    },
    [fromDate, toDate, level, resultType]
  );

  const onClearSiteData = React.useCallback(() => {
    console.log("toDate,toDate");
    !!siteRef.current && siteRef.current.select.clearValue();
    setProvince(null);
    setSiteValue(null);
  }, []);
  const onClearProvinceData = React.useCallback(() => {
    !!provinceRef.current && provinceRef.current.select.clearValue();
    setProvince(null);
    setDistrict(null);
  }, []);

  const onClearDistrictData = React.useCallback(() => {
    !!districtRef.current && districtRef.current.select.clearValue();
    setDistrict(null);
    setProvince(null);
  }, []);
  return (
    <ContainerBox>
      <Header title="Dashboard" />
      <div className="dashboard">
        <NepalMap {...{ provinceList }} />
        <div className="card" style={{ marginTop: 20 }}>
          <div className="card">
            <div
              // className="showdatacard"
              style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                columnGap: 20,
                // padding: 20,
                // borderRadius: 8,
                // background: "white",
                // border: "1px solid #f1f1f1",
              }}
            >
              <div className=" form">
                <label>Search by Site</label>
                <SelectField
                  ref={siteRef}
                  options={siteList}
                  getOptionLabel="name"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    setSiteValue(item?.id);
                    // filterDashboardData(item?.id);
                  }}
                  value={siteList?.find(
                    (item) => Number(item.id) === Number(siteValue)
                  )}
                  placeholder="Search By Site"
                  isClearable={true}
                  isSearchable={true}
                />
                <div style={{ display: "flex", flexDirection: "row" }}></div>
              </div>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="formWrapper" style={{ width: 300 }}>
                  <label>Search by Date Range</label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 20,
                      width: "100%",
                    }}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      label="Date From"
                      format="yyyy-MM-dd"
                      margin="normal"
                      value={fromDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      onChange={(date, value) => {
                        console.log("date,value", { date, value });
                        setFromDate(value);
                      }}
                    />

                    <KeyboardDatePicker
                      disableToolbar
                      label="Date To"
                      format="yyyy-MM-dd"
                      margin="normal"
                      value={toDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      onChange={(date, value) => {
                        console.log("date,value", { date, value });
                        setToDate(value);
                      }}
                    />
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                columnGap: 20,
              }}
            >
              <div className=" form">
                <label>Search by Level</label>
                <SelectField
                  options={LEVEL}
                  getOptionLabel="name"
                  getOptionValue="value"
                  onChangeValue={(item) => {
                    setLevel(item?.value);
                    // setLevelValue("");
                  }}
                  value={LEVEL?.find((item) => item.value === level)}
                  placeholder="Search By Level"
                  isClearable={true}
                  isSearchable={true}
                />
              </div>

              {level === "province" || level === "district" ? (
                <div className=" form">
                  <label>Province</label>
                  <SelectField
                    ref={provinceRef}
                    options={provinceList}
                    getOptionLabel="name"
                    getOptionValue="id"
                    onChangeValue={(item) => {
                      setProvince(item?.id);
                      // level === "province" &&
                      //   filterDashboardData(null, item?.id, null);
                    }}
                    value={provinceList?.find(
                      (item) => Number(item.id) === Number(province)
                    )}
                    placeholder="Search By Province"
                    isClearable={true}
                    isSearchable={true}
                  />
                </div>
              ) : null}

              {level === "district" ? (
                <div className=" form">
                  <label>District</label>
                  <SelectField
                    ref={districtRef}
                    options={districtList}
                    getOptionLabel="name"
                    getOptionValue="id"
                    onChangeValue={(item) => {
                      setDistrict(item?.id);
                      // level === "district" &&
                      //   filterDashboardData(null, null, item?.id);
                    }}
                    value={districtList?.find(
                      (item) => Number(item.id) === Number(district)
                    )}
                    placeholder="Search By District"
                    isClearable={true}
                    isSearchable={true}
                  />
                </div>
              ) : null}

              <div className=" form">
                <label>Result Type</label>
                <SelectField
                  options={RESULTTYPES}
                  getOptionLabel="name"
                  getOptionValue="value"
                  onChangeValue={(item) => {
                    setResultType(item?.value);
                    // level === "district" &&
                    //   filterDashboardData(null, null, item?.id);
                  }}
                  value={RESULTTYPES?.find((item) => item.value === resultType)}
                  placeholder="Search By Type"
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
            </div>
          </div>

          <div
            className="dashboard-cards"
            // style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
          >
            <AnalysisDataCard
              loading={loading}
              title="Gx Functional Rate"
              data={dashboardAnalysis?.gx_functional_rate}
            />
            <AnalysisDataCard
              loading={loading}
              title="Utilization Rate"
              data={dashboardAnalysis?.utilization_rate}
            />
            <AnalysisDataCard
              loading={loading}
              title="Positivity Rate"
              data={dashboardAnalysis?.positivity_rate}
            />
            <AnalysisDataCard
              loading={loading}
              title="RR Rate"
              data={dashboardAnalysis?.rr_rate}
            />
            <AnalysisDataCard
              loading={loading}
              title="Error rate"
              data={dashboardAnalysis?.error_rate}
            />
            <AnalysisDataCard
              loading={loading}
              title="Invalid Test Rate"
              data={dashboardAnalysis?.invalid_test_rate}
            />
          </div>
        </div>
        <div className="dashboard-graphs">
          {/* <GraphBox {...{width:1000,height:150,percentage:'90%'}}/> */}
          <div className="dashboard-graphs-piechart">
            <div className="dashboard-graphs-piechart-each">
              <h3>Enrolled Sites</h3>
              {totalGxSites && totalEnrolledSites ? (
                totalGxSites > 0 ? (
                  <PieChartBox
                    data={[
                      { name: "Enrolled", value: totalEnrolledSites },
                      {
                        name: "Not Enrolled",
                        value: totalGxSites - totalEnrolledSites,
                      },
                    ]}
                  />
                ) : (
                  <div className="dashboard-graphs-piechart-each-loading">
                    DATA NOT AVAILABLE
                  </div>
                )
              ) : (
                <div className="dashboard-graphs-piechart-each-loading">
                  <ActivityIndicator animating={true} />
                </div>
              )}
            </div>

            <div className="dashboard-graphs-piechart-each">
              <h3>Machine Operation Status Unknown</h3>
              {machineStatus ? (
                machineStatus?.delay_1_2_days?.length +
                  machineStatus?.delay_3_4_days?.length +
                  machineStatus?.delay_5_6_days?.length +
                  machineStatus?.delay_7_days?.length >
                0 ? (
                  <PieChartBox
                    chart="machine-delay"
                    data={[
                      {
                        name: "1-2 days",
                        value: machineStatus?.delay_1_2_days?.length,
                      },
                      {
                        name: "3-4 days",
                        value: machineStatus?.delay_3_4_days?.length,
                      },
                      {
                        name: "5-6 days",
                        value: machineStatus?.delay_5_6_days?.length,
                      },
                      {
                        name: "More than 7 days",
                        value: machineStatus?.delay_7_days?.length,
                      },
                    ]}
                  />
                ) : (
                  <div className="dashboard-graphs-piechart-each-loading">
                    DATA NOT AVAILABLE
                  </div>
                )
              ) : (
                <div className="dashboard-graphs-piechart-each-loading">
                  <ActivityIndicator animating={true} />
                </div>
              )}
            </div>

            {isMtb && (
              <div className="dashboard-graphs-piechart-each">
                <h3>MTB Results</h3>
                {dataCount ? (
                  dataCount?.MTB_RIF_I +
                    dataCount?.MTB_RIF_R +
                    dataCount?.MTB_RIF_S +
                    dataCount?.MTB_NOT_DETECTED +
                    dataCount?.NO_RESULT +
                    dataCount?.ERROR +
                    dataCount?.INVALID >
                  0 ? (
                    <PieChartBox
                      chart="results"
                      data={[
                        { name: "INVALID", value: dataCount?.INVALID },
                        { name: "ERROR", value: dataCount?.ERROR },
                        {
                          name: "NOT DETECTED",
                          value: dataCount?.MTB_NOT_DETECTED,
                        },
                        { name: "MTB+RIF I", value: dataCount?.MTB_RIF_I },
                        { name: "MTB+RIF R", value: dataCount?.MTB_RIF_R },
                        { name: "MTB+RIF S", value: dataCount?.MTB_RIF_S },
                        { name: "NO RESULT", value: dataCount?.NO_RESULT },
                      ]}
                    />
                  ) : (
                    <div className="dashboard-graphs-piechart-each-loading">
                      DATA NOT AVAILABLE
                    </div>
                  )
                ) : (
                  <div className="dashboard-graphs-piechart-each-loading">
                    <ActivityIndicator animating={true} />
                  </div>
                )}
              </div>
            )}
            {isCovid && (
              <div className="dashboard-graphs-piechart-each">
                <h3>Covid Results</h3>
                {covidData ? (
                  covidData?.COVID_TOTAL > 0 ? (
                    <PieChartBox
                      chart="results"
                      data={[
                        {
                          name: "COVID_INVALID",
                          value: covidData?.COVID_INVALID,
                        },
                        { name: "POSITIVE", value: covidData?.COVID_POSITIVE },
                        { name: "NEGATIVE", value: covidData?.COVID_NEGATIVE },
                        // {
                        //   name: "COVID_INDETERMINATE",
                        //   value: covidData?.COVID_INDETERMINATE,
                        // },
                        { name: "COVID_NULL", value: covidData?.COVID_NULL },
                        // {
                        //   name: "COVID_PRESUMPTIVE_POS",
                        //   value: covidData?.COVID_PRESUMPTIVE_POS,
                        // },
                      ]}
                    />
                  ) : (
                    <div className="dashboard-graphs-piechart-each-loading">
                      DATA NOT AVAILABLE
                    </div>
                  )
                ) : (
                  <div className="dashboard-graphs-piechart-each-loading">
                    <ActivityIndicator animating={true} />
                  </div>
                )}
              </div>
            )}

            <div className="dashboard-graphs-piechart-each">
              <h3>Ticket Status</h3>
              {ticketData ? (
                ticketData?.opened_count +
                  ticketData?.picked_count +
                  ticketData?.working_count +
                  ticketData?.solved_count >
                0 ? (
                  <PieChartBox
                    chart="ticket"
                    data={[
                      { name: "Opened", value: ticketData?.opened_count },
                      { name: "Picked", value: ticketData?.picked_count },
                      { name: "Working", value: ticketData?.working_count },
                      { name: "Solved", value: ticketData?.solved_count },
                    ]}
                  />
                ) : (
                  <div className="dashboard-graphs-piechart-each-loading">
                    DATA NOT AVAILABLE
                  </div>
                )
              ) : (
                <div className="dashboard-graphs-piechart-each-loading">
                  <ActivityIndicator animating={true} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="dashboard-graphs">
          <h3 style={{ marginBottom: 50 }}>MTB Results Last 6 months</h3>
          {lineChartData.length > 0 ? (
            <GraphBox
              {...{ width: 1000, height: 150, percentage: "90%" }}
              data={lineChartData}
            />
          ) : (
            <div className="dashboard-graphs-linechart-loading">
              {" "}
              <ActivityIndicator animating={true} />
            </div>
          )}
        </div>

        <div className="dashboard-cards">
          <ShowDataCard
            clicked={onClickedHandler}
            title="Total Machine Results"
            data={totalMachineResults}
          />
          <ShowDataCard
            clicked={onClickedHandler}
            title="Total No of GX Machines"
            data={totalGxMachine}
          />
          <ShowDataCard
            clicked={onClickedHandler}
            title="Total GX Sites"
            data={totalGxSites}
          />
          <ShowDataCard
            clicked={() => navigate("/sites?page=1&enroll=enrolledSite")}
            title="Enrolled GX Sites"
            data={totalEnrolledSites}
          />
          {/* <ShowDataCard clicked={()=>navigate({pathname:'/sites',state:"enrolledSite"})} title="Enrolled GX Sites" data={totalEnrolledSites} /> */}

          <ShowDataCard
            clicked={onClickedHandler}
            title="GX Machine Running Today"
            data={onlineMachine}
          />
          <ShowDataCard
            // clicked={() => navigate({ pathname: "/result", state: "ERROR" })}
            clicked={() => navigate("/result?page=1&mtbResultType=ERROR")}
            title="ERROR COUNT"
            data={dataCount?.ERROR}
          />
          <ShowDataCard
            clicked={() => navigate("/result?page=1&mtbResultType=MTB%2BRIF I")}
            title="RIF Resistant COUNT"
            data={dataCount?.MTB_RIF_R}
          />
        </div>
        <div className="dashboard-tables">
          {/* <ShowListCard
            title="GX machine warranty expiring in 6 months"
            data={expiringMachine}
          /> */}
          <ShowTable
            // loading={true}
            title="GX machine warranty expiring in 6 months"
            data={expiringMachine}
          />
          <ShowTable
            // loading={false}
            title="GX machine that needs calibration in 6 months"
            data={calibrationMachine}
          />
          {/* <ShowListCard
            title="GX machine that needs calibration in 6 months"
            data={calibrationMachine}
          /> */}
        </div>
        <div className="displayMessage">
          Default values are of current fiscal year.
        </div>
      </div>
    </ContainerBox>
  );
};

export default DashboardPage;
