// ALL ROUTES, PATH AND RESTRICTION ARE ADDED HERE

// ROUTES
import LoginPage from "../loginPage/LoginPage";
import SigninPage from "../signinPage/SigninPage";
import DashboardPage from "../dashboardPage/DashboardPage";
// import DashboardDetailsPage from "../dashboardDetailsPage/DashboardDetailsPage";
import NotFoundPage from "../notFoundPage/NotFoundPage";
import MachineStatusPage from "../machineStatusPage/MachineStatusPage";
import RunningToday from "../machineStatusPage/seperatePage/RunningToday";
import DelaySeven from "../machineStatusPage/seperatePage/DelaySeven";
import GxSitesPage from "../gxSitesPage/GxSitesPage";
import GxmachinePage from "../gxMachinePage/GxMachinePage";
import ResultPage from "../resultPage/ResultPage";
import CovidResultPage from "../resultPage/CovidResultPage";
import TicketPage from "../ticketPage/TicketPage";
import DashboardDetailPage from "../dashboardDetailPage/DashboardDetailPage";
import AddGxMachine from "../gxMachinePage/addGxMachine/AddGxMachine";
import AddGxSite from "../gxSitesPage/addgxSite/AddGxSite";
// import EditMachineStatus from "../machineStatusPage/editMachineStatus/EditMachineStatus";
import AddTicket from "../ticketPage/addTicket/AddTicket";
import UsersPage from "../usersPage/UsersPage";
import AddUsers from "../usersPage/addUsers/AddUsers";
import UsersDetails from "../usersPage/usersDetails/UsersDetails";
import GxSiteDetailPage from "../gxSiteDetailPage/GxSiteDetailPage";
import ChangePasswordPage from "../changePasswordPage/ChangePasswordPage";
import ForgetPasswordPage from "../loginPage/ForgetPasswordPage";
import NotificationPage from "../notificationPage/notificationPage";
import EditGxMachine from "../gxMachinePage/editGxMAchine/EditGxMachine";
import EditGxSite from "../gxSitesPage/editgxSite/EditGxSite";
import NotificaitonThreshold from "../notificationPage/notificationThreshold";
import EditTicket from "../ticketPage/editTicket/EditTicket";
import EditComponent from "../gxMachinePage/editGxMAchine/EditComponent";
import DelayFiveSix from "../machineStatusPage/seperatePage/DelayFiveSix";
import DelayThreeFour from "../machineStatusPage/seperatePage/DelayThreeFour";
import DelayOneTwo from "../machineStatusPage/seperatePage/DelayOneTwo";
import CommentPage from "../ticketPage/comentPage/CommentPage";
import ViewTicket from "../ticketPage/viewTicket/ViewTicket";
import { ReportPage } from "../report/reportPage";
import { WorkingModuleStatusPage } from "../report/workingModuleStatus/workingModuleStatus";
import { SiteWiseRate } from "../report/siteWiseRate/siteWiseRate";
import { SiteWiseRateGraph } from "../report/siteWiseRateGraph/siteWiseRateGraph";
import { AddTicketPage } from "../ticketPage/addTicket/AddTicketNew";
import UltraResultPage from "../resultPage/UltraResultPage";
import XDRResultPage from "../resultPage/XDRResultPage";

// PUBLIC PATHS => THESE ARE ACCESSIBLE WITHOUT LOGIN
// PUBLIC PATHS HAS restricted key => IF TRUE THEY CANNOT BE ACCESSED WITH LOGGED USER
// key prop can be acessed in withLink by navigation.routes.[key] which gives object { name, path }
export const PUBLIC_PATHS = [
  {
    key: "Root",
    name: "Root",
    path: "/",
    component: LoginPage,
    restricted: true,
  },
  {
    key: "Login",
    name: "Login",
    path: "/log-in",
    component: LoginPage,
    restricted: true,
  },
  {
    key: "Reset Password",
    name: "Reset Password",
    path: "/password/reset/",
    component: ForgetPasswordPage,
    restricted: true,
  },
  {
    key: "Signin",
    name: "Signin",
    path: "/sign-in",
    component: SigninPage,
    restricted: true,
  },
  {
    // Define just path and component keys for NotFoundPage
    path: null,
    component: NotFoundPage,
  },
];

// PUBLIC PATHS => THESE ARE NOT ACCESSIBLE WITHOUT LOGIN
export const PRIVATE_PATHS = [
  // {
  //   key: "Dashboard",
  //   name: "Dashboard",
  //   path: "/",
  //   component: DashboardPage
  // },
  {
    key: "Dashboard",
    name: "Dashboard",
    path: "/dashboard",
    exact: true,
    component: DashboardPage,
    visible: true,
  },
  {
    key: "Dashboard Details",
    name: "Dashboard Details",
    path: "/dashboard/:id",
    exact: true,
    component: DashboardDetailPage,
    visible: true,
  },
  {
    key: "Machine Status",
    name: "Machine Status",
    path: "/machine-status",
    component: MachineStatusPage,
    subPaths: [
      {
        key: "Running Today",
        name: "Running Today",
        path: "/running-today",
        component: RunningToday,
      },
      {
        key: "Delay Seven",
        name: "Delay Seven",
        path: "/delay-seven",
        component: DelaySeven,
        visible: true,
      },
      {
        key: "Delay Five Six",
        name: "Delay Five Six",
        path: "/delay-five-six",
        component: DelayFiveSix,
      },
      {
        key: "Delay Three Four",
        name: "Delay Three Four",
        path: "/delay-three-four",
        component: DelayThreeFour,
      },
      {
        key: "Delay One Two",
        name: "Delay One Two",
        path: "/delay-one-two",
        component: DelayOneTwo,
      },
      {
        key: "Edit MachineStatus",
        name: "Edit MachineStatus",
        path: "/edit/:id",
        component: EditTicket,
      },
    ],
  },

  {
    key: "GX Sites",
    name: "GX Sites",
    path: "/sites",
    component: GxSitesPage,
    subPaths: [
      {
        key: "Add GxSite",
        name: "Add GxSite",
        path: "/add",
        component: AddGxSite,
      },
      {
        key: "Edit GxSite",
        name: "Edit GxSite",
        path: "/edit/:id",
        component: EditGxSite,
      },
      {
        key: "Site Details",
        name: "Site Details",
        path: "/:id",
        component: GxSiteDetailPage,
      },
    ],
  },
  {
    key: "GX Machine",
    name: "GX Machines",
    path: "/gx-machine",
    exact: true,
    component: GxmachinePage,
    visible: true,
  },
  {
    key: "Results",
    name: "MTB/RIF Results",
    path: "/result",
    exact: true,
    component: ResultPage,
    visible: true,
  },
  {
    key: "Covid Results",
    name: "SARS-CoV-2 Results",
    path: "/covid-result",
    exact: true,
    component: CovidResultPage,
    visible: true,
  },

  {
    key: "Ultra Results",
    name: "Ultra Results",
    path: "/ultra-result",
    exact: true,
    component: UltraResultPage,
    visible: true,
  },

  {
    key: "XDR Results",
    name: "XDR Results",
    path: "/xdr-result",
    exact: true,
    component: XDRResultPage,
    visible: true,
  },

  {
    key: "Tickets",
    name: "Tickets",
    path: "/ticket",
    exact: true,
    component: TicketPage,
    visible: true,
  },
  {
    key: "Add GxMachine",
    name: "Add GxMachine",
    path: "/gx-machine/add",
    exact: true,
    component: AddGxMachine,
    visible: true,
  },
  {
    key: "Edit GxMachine",
    name: "Edit GxMachine",
    path: "/gx-machine/edit/:id",
    exact: true,
    component: EditComponent,
    visible: true,
  },
  // {
  //   key: "Add GxSite",
  //   name: "Add GxSite",
  //   path: "/sites/add",
  //   exact: true,
  //   component: AddGxSite,
  //   visible: true,
  // },
  // {
  //   key: "Edit GxSite",
  //   name: "Edit GxSite",
  //   path: "/sites/edit/:id",
  //   exact: true,
  //   component: EditGxSite,
  //   visible: true,
  // },
  // {
  //   key: "Site Details",
  //   name: "Site Details",
  //   path: "/sites/:id",
  //   exact: true,
  //   component: GxSiteDetailPage,
  // },
  // {
  //   key: "Edit MachineStatus",
  //   name: "Edit MachineStatus",
  //   path: "/machine-status/edit/:id",
  //   exact: true,
  //   component: EditTicket,
  //   visible: true,
  // },
  {
    key: "Add Ticket",
    name: "Add Ticket",
    path: "/ticket/add",
    exact: true,
    component: AddTicketPage,
    // component: AddTicket,
    visible: true,
  },
  {
    key: "Edit Ticket",
    name: "Edit Ticket",
    path: "/ticket/edit/:id",
    exact: true,
    component: EditTicket,
    visible: true,
  },

  {
    key: "Detail Ticket",
    name: "Detail Ticket",
    path: "/ticket/view/:id",
    exact: true,
    component: ViewTicket,
    visible: true,
  },

  {
    key: "CommentTicket",
    name: "Comment Ticket",
    path: "/ticket/comment/:id",
    exact: true,
    component: CommentPage,
    visible: true,
  },
  {
    key: "Users",
    name: "Users",
    path: "/users",
    exact: true,
    component: UsersPage,
    visible: true,
  },
  {
    key: "Add Users",
    name: "Add Users",
    path: "/users/add",
    exact: true,
    component: AddUsers,
    visible: true,
  },
  {
    key: "Edit Users",
    name: "Edit Users",
    path: "/users/edit/:id",
    exact: true,
    component: AddUsers,
    visible: true,
  },
  {
    key: "Users Details",
    name: "Users Details",
    path: "/users/:id",
    exact: true,
    component: UsersDetails,
    visible: true,
  },

  {
    key: "ChangePassword",
    name: "Change Password",
    path: "/password/change",
    exact: true,
    component: ChangePasswordPage,
  },
  {
    key: "Notification",
    name: "Notification",
    path: "/notification",
    exact: true,
    component: NotificationPage,
  },
  {
    key: "NotificationThreshold",
    name: "Count Threshold",
    path: "/notification-threshold",
    exact: true,
    component: NotificaitonThreshold,
  },
  {
    key: "Report",
    name: "Report",
    path: "/report",
    exact: true,
    component: ReportPage,
    nestedPaths: [
      {
        name: "Site Report",
        path: "/site",
        exact: true,
        component: SiteWiseRate,
        props: {
          group: "Report",
        },
      },
      {
        name: "Site Report Graph",
        path: "/site-report",
        exact: true,
        component: SiteWiseRateGraph,
        props: {
          group: "Report",
        },
      },
      {
        name: "Module Status",
        path: "/working-module-status",
        exact: true,
        component: WorkingModuleStatusPage,
        props: {
          group: "Report",
        },
      },
    ],
  },
  // {
  //   key: "Working Module Status",
  //   name: "Module Status",
  //   path: "/working-module-status",
  //   exact: true,
  //   component: WorkingModuleStatusPage,
  // },
];
