import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import { Button } from "@material-ui/core";

import "react-day-picker/lib/style.css";

import { toast } from "react-toastify";

import { APIS } from "../../config/Api.config";
import { api } from "../../helpers/Api.helper";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import Header from "../common/header/Header.common";
import { ActivityIndicator } from "../common/hoc";

const NotificaitonThreshold = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [loader, setLoader] = useState(false);

  const [thresholdData, setThresholdData] = useState();
  const fetch = async () => {
    let res = await api(APIS.notification_threshold);

    setThresholdData(res?.data);
  };
  useEffect(() => {
    fetch();
  }, []);

  const onSubmit = async (data) => {
    // console.log(data)
    setLoader(true);
    let body = {
      no_of_error_count: data.errorCount,
      no_of_result_count: data.resultCount,
    };
    let res = await api(APIS.notification_threshold, "POST", body);
    // console.log("update responce",res)
    if (res.status === 200) {
      toast.success("Threshold successfuly Updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      setLoader(false);
      fetch();
    }
    setLoader(false);
  };

  const cancelHandler = () => {
    window.history.back();
  };

  return (
    <ContainerBox>
      <Header title="Notification Threshold" />
      <div className="addgxmachine">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addgxmachine-form">
            <div className="addgxmachine-form-code form">
              <label>Error Count</label>
              <input
                name="errorCount"
                defaultValue={thresholdData?.no_of_error_count}
                ref={register({ required: true })}
                className={`${errors.code && "showerror"} `}
              />
            </div>

            <div className="addgxmachine-form-serial form">
              <label>Result Count</label>
              <input
                name="resultCount"
                defaultValue={thresholdData?.no_of_result_count}
                ref={register({ required: true })}
                className={`${errors.serial && "showerror"} `}
              />
            </div>

            <div className="addgxmachine-form-row">
              <ActivityIndicator animating={loader}>
                <Button type="submit" variant="contained" color="primary">
                  submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelHandler}
                >
                  Cancel
                </Button>
              </ActivityIndicator>
            </div>
          </div>
        </form>
      </div>
    </ContainerBox>
  );
};

export default NotificaitonThreshold;
