import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Helpers";
import moment from "moment";
export const ModuleHistory = ({ serialNo }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async (id) => {
      setLoading(true);
      let site_result = await api(
        `${APIS.moduleHistory}/get_module_history/${serialNo}/`
      );
      console.log("site_result", site_result);
      if (site_result?.status === 200) {
        setData(site_result.data);
        setLoading(false);
      }
      setLoading(false);
      // setGxDetail(site_result?.data);
    })();
    return () => {
      setData([]);
    };
  }, []);

  return (
    <div style={{ color: "black" }}>
      <div>
        {loading ? (
          <div>
            <h3>Module History</h3>
            Loading
          </div>
        ) : data?.length > 0 ? (
          <MaterialTable
            title="Module History"
            columns={[
              { title: "Site Name", field: "site_name" },
              { title: "Machine Name.", field: "machine_name" },
              { title: "Result Type.", field: "result_type" },
              {
                title: "Start Date",
                field: "start_date",
                render: (rowData) =>
                  moment(rowData.start_date).format("YYYY-MM-DD"),
              },
              {
                title: "End Date",
                field: "last_date",
                render: (rowData) =>
                  moment(rowData.last_date).format("YYYY-MM-DD"),
              },
            ]}
            data={data}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            isLoading={false}
            options={{
              paging: false,
              search: false,
            }}
          />
        ) : (
          <div>
            <h3>Module History</h3>
            No data Found
          </div>
        )}
      </div>
    </div>
  );
};
